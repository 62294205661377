.modal {
  padding: 2.5rem 4%;
  text-align: center;

  svg {
    width: 180px;
    margin-bottom: 1rem;
  }
}
.ttl {
  color: var(--primary-blue);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.txt {
  color: var(--primary-blue);
  text-align: center;
  font-size: 15px;
  margin-bottom: 1.5rem;
  font-weight: 300;
}
.btn {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #fff;
  background: var(--secondary-blue);
  border-color: var(--secondary-blue);
  display: block;
  padding: 12px;
  border-radius: 6px;
}
