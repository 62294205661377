.footerBg {
  background: #101323;
  color: #fff;
}
.footer {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.sec1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 7rem;

  &__txt {
    font-family: "Darker Grotesque";
    font-weight: 600;
    font-size: 32px;
    max-width: 600px;
    margin-bottom: 1rem;
  }

  &__newsletter {
    min-width: 320px;

    &__ttl {
      font-family: "Darker Grotesque";
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 6px;
    }

    &__txt {
      font-size: 15px;
      margin-bottom: 1.5rem;
      font-weight: 300;
    }

    &__inputWrap {
      display: grid;
      grid-template-columns: 1fr 84px;

      input {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        padding-left: 14px;
        background: #fff;
        border: none;
      }

      button {
        background: var(--secondary-blue);
        color: #fff;
        border: none;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 12px 14px;
      }
    }
  }
}
.error {
  font-size: 12px;
  color: red;
  margin-top: 6px;
}
.sec2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  border-top: 1px solid #475467;
  font-size: 15px;
  font-weight: 300;
  flex-wrap: wrap;
  gap: 1rem;

  &__links {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-weight: 400;
  }
}

@media screen and (max-width: 600px) {
  .sec1 {
    &__txt {
      font-weight: 500;
      font-size: 22px;
      max-width: 400px;
    }

    &__newsletter {
      min-width: unset;
      width: 100%;
      max-width: 360px;
      &__ttl {
        font-size: 20px;
      }
    }
  }
  .sec2 {
    font-size: 14px;
    gap: 2rem;

    &__links {
      gap: 1rem;
    }
  }
}
