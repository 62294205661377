.modal {
  text-align: center;
  padding: 2rem;
}
.ttl {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 1.5rem;
  color: #030229
}
.txt {
  font-size: 16px;
  margin-bottom: 2rem;
  color: #000;
  font-weight: 500;
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
}
.btn {
  margin: auto;
  padding: 8px 22px;
  font-weight: 400;
  font-size: 14px;
}
