.modal {
  padding: 2rem;
  max-width: 500px;

  svg {
    width: 150px;
    margin-bottom: 1rem;
  }
}
.ttl {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
  color: #101323;
}
.txt {
  font-size: 15px;
  margin-bottom: 1.5rem;
  color: #667085;
}
.checkList {
  margin-bottom: 2rem;
}
.checkWrap {
  display: flex;
  margin-bottom: 1rem;

  input {
    width: 18px;
    height: 18px;
    margin-right: 1rem;
    accent-color: var(--secondary-blue);
  }
}
.btn {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
}
