.wrapper {
  padding: 2rem 1rem 1rem 0;
}
.container {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 1.5rem;
  padding-top: 1rem;
  align-items: flex-start;

  &--observative {
    grid-template-columns: 1fr;
    padding-left: 1rem;
  }
}
.sec1 {
  display: grid;
  gap: 4rem;

  &__expectation {
    > p {
      color: #667085;
      margin-left: 1rem;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}
.qualities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 3rem;

  &__item {
    line-height: 1.6;

    > div {
      padding: 8px 1rem;
    }

    &__ttl {
      font-weight: 600;
      margin-bottom: 2px;
      font-size: 15px;
    }

    ul {
      padding-left: 1rem;
      color: #667085;
    }
  }
}
.noBullet {
  list-style: none;
  padding-left: 0 !important;

  li {
    display: flex;
    align-items: start;
    gap: 8px;
  }

  svg {
    width: 16px;
    margin-top: 6px;
  }
}
.ttl {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  text-transform: capitalize;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 12px;
  margin-bottom: 2rem;

  span {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    font-size: 14px;

    svg {
      margin-left: 10px;
      width: 18px;
      height: 18px;
    }

    &.green {
      color: #149b32;
    }

    &.red {
      color: #e74444;
    }
  }
}
.chevron {
  display: none;
}
.moreInsightsBtn {
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  padding: 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--secondary-blue);
    }
  }
}
@media screen and (max-width: 900px) {
  .qualities {
    gap: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
    gap: 3rem;

    &--observative {
      padding-left: 0;
    }
  }
  .sec1 {
    gap: 1.5rem;
  }
  .wrapper {
    padding-left: 1rem;
  }
  .qualities {
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .container {
    gap: 2rem;
  }
  .qualities {
    display: grid;
    grid-template-columns: 1fr;
  }
  svg.chevron {
    display: block;
    transform: rotate(-90deg);
    width: 8px !important;
    margin-left: 2rem !important;

    &.rotate {
      transform: rotate(90deg);
    }
  }
  .ttl {
    font-size: 14px;
    background: #fafafb;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: none;
  }
}
