.hero {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.content {
  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    max-width: 600px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;

    span {
      color: var(--secondary-blue);
    }
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    max-width: 480px;
    margin-bottom: 2rem;
    font-size: 18px;
    letter-spacing: normal;
    line-height: 1.5;
  }
}
.img {
  width: 100%;
  max-width: 549px;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .hero {
    grid-template-columns: 1fr;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .content {
    &__ttl {
      font-size: 40px;
      max-width: 400px;
    }
    &__txt {
      font-size: 15px;
      max-width: unset;
      margin-bottom: 0;
    }
  }
  .img {
    margin: auto;
  }
}
