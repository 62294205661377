.txt {
  margin-bottom: 2rem;
  margin-top: -1.5rem;
}
.navSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  &__tabs {
    background: #eaecf0;
    padding: 2px;
    border-radius: 6px;

    button {
      padding: 6px 1rem;
      border-radius: 4px;
      border: none;
      color: #344054;
      font-weight: 500;
      font-size: 15px;

      &.active {
        background: #fff;
        color: #101828;
      }
    }

    &--1 {
      button {
        width: 120px;
      }
    }

    &--2 {
      button {
        width: 80px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navSec {
    margin-bottom: 4rem;

    &__tabs {
      &--1 {
        button {
          width: fit-content;
        }
      }

      &--2 {
        button {
          width: fit-content;
        }
      }
    }
  }
}
