.wrapper {
  padding: 2rem 1rem 1rem 0;
}
.container {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 1.5rem;
  padding-top: 1rem;
  align-items: flex-start;

  &--observative {
    grid-template-columns: 1fr;
    padding-left: 1rem;
  }
}
.sec1 {
  display: grid;
  gap: 4rem;
}
.extra_info {
  color: #667085;
  line-height: 1.5;
  margin-top: 1rem;
  font-size: 15px;
}
.moreInsightsBtn {
  font-weight: 400;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  padding: 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--secondary-blue);
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
    gap: 3rem;

    &--observative {
      padding-left: 0;
    }
  }
  .sec1 {
    gap: 1.5rem;
  }
  .wrapper {
    padding-left: 1rem;
  }
}
