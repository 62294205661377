.section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 2rem;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 8rem;
  overflow: hidden;
}
.content {
  &__ttl {
    color: #101828;
    font-family: "Darker Grotesque";
    font-weight: 700;
    font-size: 44px;
    max-width: 550px;
    margin-bottom: 1rem;
    span {
      color: var(--secondary-blue);
    }
  }
  &__txt {
    color: #667085;
    font-size: 18px;
    margin-bottom: 3rem;
  }
  &__cta {
    background: var(--secondary-blue);
    color: #fff;
    padding: 1rem 4.5rem;
    border-radius: 4px;
    display: block;
    width: fit-content;
  }
}
.img {
  width: 100%;
  max-width: 564px;
}

@media screen and (max-width: 800px) {
  .section {
    gap: 3rem;
    padding-bottom: 4rem;
    padding-top: 2rem;
  }
  .content {
    &__ttl {
      font-size: 36px;
      line-height: 1.2;
    }
    &__txt {
      font-size: 15px;
    }
  }
  .img {
    margin: auto;
    width: 120%;
  }
}
