.form {
  display: flex;
  align-items: flex-start;
  max-width: 600px;
}
.inputWrap {
  margin-bottom: 0;

  label {
    font-size: 17px;
    margin-bottom: 12px;
    font-weight: 700;
  }
}
.btn {
  margin-left: 2rem;
  min-width: 140px;
  height: 44px;
  border-radius: 8px;
  margin-top: 30px;
}
.req {
  width: 100%;
  color: #e74444;
  margin-bottom: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 600px) {
  .form {
    flex-direction: column;
  }

  .btn {
    margin-top: 2rem;
    margin-left: auto;
  }
}
