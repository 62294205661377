.bg {
  background: #fff;
  overflow: hidden;
}
.container {
  display: flex;
  align-items: center;
  gap: 3rem;
  animation: scroll 20s linear infinite;
  padding-top: 3rem;
  padding-bottom: 3rem;

  * {
    transition: 0.3s;
  }

  span {
    font-family: "Darker Grotesque";
    font-size: 90px;
    font-weight: 600;
    color: #101323;
    display: flex;
    align-items: center;
    gap: 3rem;
    white-space: nowrap;

    &::after {
      content: "";
      background: url(../../../assets/icons/sparklesIcon.svg);
      display: block;
      width: 32px;
      height: 32px;
      margin-bottom: -1rem;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1720px);
  }
}

@media screen and (max-width: 600px) {
  .container {
    animation-duration: 6px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 2rem;

    span {
      font-size: 60px;
      gap: 2rem;
    }
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-1250px);
    }
  }
}
