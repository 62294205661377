.modalDialog {
  background: #242a3330;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1500;
  display: flex;
  overflow: auto;
  padding: 1rem 0;
}
.modalContent {
  background: #fff;
  max-width: 450px;
  margin: auto;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  width: 90%;
  
  &::-webkit-scrollbar {
    width: 0;
  }
}

.left {
  margin-left: 1rem;
}

.right {
  margin-right: 1rem;
}
