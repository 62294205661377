.wrapper {
  padding: 2rem 1rem 1rem 0;
}
.container {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 1.5rem;
  padding-top: 1rem;
  align-items: flex-start;

  &--observative {
    grid-template-columns: 1fr;
    padding-left: 1rem;
  }
}
.sec1 {
  display: grid;
  gap: 4rem;

  &__expectation {
    > p {
      color: #667085;
      margin-left: 1rem;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.ttl {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 6px;
  text-align: center;
}

.details {
  padding: 2rem;

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  &__ttl {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #eaecf0;
  }

  ol {
    margin-bottom: 1rem;
    line-height: 2;

    p {
      font-weight: 600;
      margin-bottom: 4px;
      color: #101323;
    }

    li {
      margin-left: 1rem;
      color: #667085;
    }
  }
}
.mobile__legend {
  display: none;
}
.points {
  // color: #667085;
  line-height: 1.5;
  margin-top: 1rem;
  // font-size: 15px;

  padding-left: 1rem;
  color: #667085;
}
@media screen and (max-width: 900px) {
  .container {
    padding: 1rem;
    grid-template-columns: 1fr;
    margin-bottom: 1.5rem;
    padding-left: 0;

    &--observative {
      padding-left: 0;
    }
  }
  .wrapper {
    padding-left: 1rem;
  }
  .sec1 {
    &__legend {
      display: none;
    }
  }
  .result {
    grid-template-columns: 1fr;

    &__label {
      font-size: 14px;
    }
  }
  .ttl {
    font-size: 14px;
  }
  .details {
    padding: 1rem;
    > div {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 1rem;
    }
  }
  .mobile__legend {
    display: block;
    margin-bottom: 1rem;
  }
}
