.legend {
  display: grid;
  gap: 6px;

  &__item {
    border-left: 4px solid;
    padding: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 15px;
    background: #f9fafb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;

    &--blue {
      border-color: #605bff;
    }

    &--red {
      border-color: #e62e2e;
    }

    p {
      color: #101323;
    }
  }
}
.blueIcon {
  path {
    fill: #605bff;
  }
}
