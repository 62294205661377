.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }

  .txt {
    margin-bottom: 12px;
  }

  .skipBtn {
    padding: 4px 1.8rem;
    font-size: 14px;
    border-radius: 8px;
    color: var(--secondary-blue);
  }
}
.skillSet {
  max-width: 700px;
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
}
.skill {
  padding: 1rem;
  display: flex;
  border-radius: 10px;
  border: 1px solid rgba(161, 161, 176, 0.2);
  background: rgba(249, 249, 249, 0.2);
  flex-direction: column;

  &.disable {
    opacity: 0.6;
    cursor: not-allowed;
  }

  input {
    margin-right: 6px;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-bottom: 10px;
  }

  span {
    display: block;
    margin-bottom: 1rem;
    overflow: hidden;
    word-break: break-word;
    font-weight: 300;

    &.ttl {
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 16px;
    }
  }

  .ratingSec {
    // margin-left: 20px;
    margin-top: auto;
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}
.error {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.red {
  border-color: #e74444;
}
@media screen and (max-width: 600px) {
  .skillSet {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}
