.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 2rem;
  width: 100%;
}
.question {
  font-size: 15px;
  background: transparent;
  padding: 0;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 600;
  white-space: break-spaces;
}
.message {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.input {
  height: 44px;
  padding-left: 1rem;
  background: transparent;
  font-size: 14px;
  padding-right: 10px;
  color: var(--text-secondary);
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(1, 33, 105, 0.5);

  &:disabled {
    cursor: not-allowed;
  }
}
.input::placeholder,
.textarea::placeholder {
  color: #6b6b75;
}
.textarea {
  padding-left: 1rem;
  padding-top: 12px;
  border-radius: 6px;
  border: 1px solid rgba(1, 33, 105, 0.5);
  background: transparent;
  font-size: 14px;
  resize: none;
  height: 200px;
  outline: none;
}
.icon {
  position: absolute;
  right: 10px;
  top: 42px;
  width: 20px;
  height: auto;
  cursor: pointer;
}
.error label {
  color: #e74444;
}
.error .input,
.error .textarea {
  border-color: #e74444;
}
.req {
  color: #e74444;
  font-weight: 600;
}

.hasValue {
  background: #fbfbff;
}
.instruction {
  font-size: 13px;
  font-weight: 300;
  margin-top: 4px;
}
.imgWrap {
  margin-bottom: 2rem;
  img {
    height: 300px;
    border-radius: 4px;
    object-fit: contain;
    background: #fafbfc;
    max-width: 500px;
    width: 100%;
  }

  p {
    font-size: 13px;
    font-weight: 300;
    margin-top: 10px;
  }
}
