.header {
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 4rem;
  position: relative;

  &--print {
    margin-top: -2rem;
  }
}
.candidate {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}
.download {
  transform: rotate(180deg);
}
.scoreWrap {
  position: relative;
  width: 240px;
  height: 240px;
  margin-right: 3rem;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 110%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span:first-of-type {
      font-size: 72px;
      line-height: 1;
      &::after {
        content: "%";
      }
    }

    span:nth-of-type(2) {
      font-size: 20px;
    }

    &.green {
      color: #149b32;
    }

    &.orange {
      color: #f29339;
    }

    &.red {
      color: #d11a2a;
    }

    &.blue {
      color: #6361f9;
    }
  }

  .score {
    width: 100%;
  }
}
.info {
  width: calc(100% - 240px);

  &__content {
    &__hd {
      color: var(--secondary-blue);
      font-weight: 400;
      margin-bottom: 4px;
      font-size: 15px;
    }

    &__name {
      font-size: 32px;
      font-weight: 600;
      display: flex;
      gap: 1rem;
      align-items: center;
      color: #101323;
      margin-bottom: 10px;
    }

    &__contact {
      color: #667085;
      font-size: 15px;
      margin-bottom: 2rem;

      a {
      }

      span {
      }
    }

    &__btns {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;

      button,
      a {
        display: flex;
        height: 40px;
        padding: 12px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid var(--Grayscale-30, #eaecf0);
        background: var(--Grayscale-white, #fff);
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.06),
          0px 2px 4px -2px rgba(16, 24, 40, 0.04);
        color: var(--secondary-blue);
        font-weight: 400;
        font-size: 13px;

        svg {
          min-width: 18px;
          width: 18px;
          height: 18px;

          path {
            fill: var(--secondary-blue);
          }
        }
      }
    }
  }

  // p {
  //   margin-bottom: 12px;
  //   span {
  //     font-weight: 600;
  //   }
  // }

  // > a {
  //   margin-bottom: 12px;
  //   display: block;
  //   width: fit-content;
  // }

  // a {
  //   color: var(--secondary-blue);
  //   text-decoration: underline;
  // }

  // button {
  //   margin-left: auto;
  //   color: var(--secondary-blue);
  //   padding: 10px 24px;
  //   letter-spacing: unset;
  //   border-radius: 8px;
  //   margin-bottom: 1rem;
  // }
}
.status {
  text-transform: capitalize;
  border-radius: 24px;
  width: fit-content;
  font-weight: 400;
  padding: 4px 12px;
  text-align: center;
  min-width: 70px;
  display: block;
  font-size: 13px;

  &__consider {
    color: #605bff;
    background: #3a974c10;
  }

  &__to_interview {
    color: #149b32;
    background: #3a974c10;
  }

  &__assessed {
    color: #f29339;
    background: #f2933910;
  }

  &__reject, &__malpractice {
    color: #d11a2a;
    background: #d11a2a10;
  }
}
.viewReport {
  position: absolute;
  right: 2rem;
  top: 2rem;
}

.summaryBody {
  margin-top: 1rem;
  &__summary {
    color: #667085;
    margin-bottom: 1rem;
  }
  &__points {
    background: rgba(229, 225, 252, 0.4);
    padding: 1rem;
    border-radius: 0px 8px 8px 0px;
    border-left: 4px solid var(--secondary-blue);
    color: #667085;

    p {
      margin-bottom: 6px;
    }

    ul {
      padding-left: 1rem;
      line-height: 1.6;
    }
  }
}

@media screen and (max-width: 900px) {
  .download {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .header {
    padding: 1.5rem 1rem;
    align-items: center;
    margin-top: 0;
  }
  .scoreWrap {
    width: 180px;
    height: 180px;
    margin-right: 1rem;

    p {
      span:first-of-type {
        font-size: 48px;
      }
      span:nth-of-type(2) {
        font-size: 18px;
      }
    }
  }
  .info {
    font-size: 14px;
    width: calc(100% - 180px);
    display: grid;
  }
  .viewReport {
    position: unset;
  }
}

@media screen and (max-width: 600px) {
  .candidate {
    display: grid;
    gap: 1.5rem;
  }

  .scoreWrap {
    width: 100%;
    height: auto;
    max-width: 150px;
    margin: auto;

    &--full {
      margin-top: 1rem;
    }

    p {
      span:first-of-type {
        font-size: 20px;
      }
      span:nth-of-type(2) {
        font-size: 12px;
      }
    }
  }
  .info {
    width: 100%;

    &__content {
      &__hd {
        font-size: 13px;
      }

      &__name {
        font-size: 22px;
      }

      &__btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      button {
        padding: 10px 16px;
        height: unset;
        font-size: 14px;
      }
    }
  }
  .viewReport,
  .shareReport {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .viewReport--full {
    right: 1rem;
    box-shadow: unset;
    top: -1rem;
    width: fit-content;
  }
  .status {
    font-size: 13px;
    font-weight: 500;
  }
}
