.container {
  box-shadow: 0px 2px 6px rgb(0 0 0 / 10%);
  position: fixed;
  z-index: 1502;
  background: #fff;
  padding: 12px;
  border-radius: 4px;
  top: 2rem;
  right: 2rem;
  border-left: 5px solid #000;
  display: flex;
  align-items: flex-start;
  max-width: 400px;
  width: 80%;
}
.error {
  border-color: #f72f2f;
  color: #f72f2f;
}
.warning {
  border-color: #c2410c;
  color: #c2410c;
}
.success {
  border-color: #10bc50;
  color: #10bc50;
}
.info {
  border-color: var(--secondary-blue);
  color: var(--secondary-blue);
}

.body {
  /* margin-left: 10px; */
}
p.ttl {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 600;
}
p.txt {
  margin: 0;
  font-size: 14px;
  color: #505050;
}
