.question {
  font-weight: 600;
  margin-bottom: 2rem;
}
.adminRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  font-weight: 400;
  margin-bottom: 1.5rem;

  svg {
    width: 32px;
  }
}
.admin {
  margin-bottom: 3rem;
}

.empathyTtl {
  color: rgba(0, 0, 0, 0.91);
  margin-bottom: 2rem;
}

.resilenceRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  font-weight: 400;
  margin-bottom: 1.5rem;

  svg {
    width: 32px;
  }

  label {
    font-weight: 600;
  }
}
.researchQuestion {
  font-weight: 600;
  margin-bottom: 1rem;
}

.otherInput {
  max-width: 600px;
  margin-top: -2.5rem;
  margin-left: 3rem;
  margin-bottom: 3rem;
  width: calc(100% - 3rem);
}

.techCuriosity {
  margin-bottom: 3rem;

  &__input {
    display: grid;
    grid-template-columns: 2fr 1fr 100px;
    gap: 1rem;
    max-width: 700px;
    margin-bottom: 2rem;

    button {
      background: #2ac769;
      border-color: #2ac769;
    }

    > div {
      margin: 0;
    }
  }

  &__tools {
    border: 0.5px solid #605bff50;
    max-width: 584px;
  }

  &__toolHd {
    background: rgba(96, 91, 255, 0.02);
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1rem 2rem;
    color: rgba(0, 0, 0, 0.8);
  }

  &__tool {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 1rem 2rem;
    background: #fff;
    border-top: 1px solid #f8f8f8;
    position: relative;

    svg {
      position: absolute;
      right: 1rem;
      width: 11px;
      top: 20px;
    }
  }
}

.nextBtn {
  margin: auto;
  width: 220px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5rem;
  border-radius: 10px;
  height: 50px;
}
.errorMessage {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.red {
  color: #e74444;
}
@media screen and (max-width: 800px) {
  .adminRating {
    flex-direction: column;
    align-items: flex-start;

    > div {
      margin-left: 1rem;
      margin-top: 10px;

      svg {
        width: 28px;
      }
    }
  }

  .resilenceRating {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;

    > div {
      margin-top: 10px;

      svg {
        width: 28px;
      }
    }
  }


  .techCuriosity {

    &__input {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    button {
      font-size: 14px;
      font-weight: 400;
      width: 72px;
    }
  }
}
