.rightFitBg {
  background: #fff;
}
.rightFit {
  padding-top: 4rem;
  padding-bottom: 4rem;

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 600;
    max-width: 800px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;

    span {
      color: var(--secondary-blue);
    }
  }
}
.benefits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  > div {
    background: #fcfcfd;
    border-radius: 16px;

    &:first-of-type,
    &:last-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
      display: grid;
      grid-template-columns: 1fr 1.2fr;
      gap: 2rem;
      align-items: center;
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      display: flex;
      flex-direction: column-reverse;

      .benefit__content {
        margin-bottom: auto;
      }
    }
  }
}
.benefit {
  &__content {
    padding: 2rem 2rem 2rem 3rem;
  }
  &__tag {
    text-transform: uppercase;
    color: var(--secondary-blue);
  }
  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }
  &__txt {
    color: #667085;
    font-size: 18px;
    line-height: 1.6;
  }
  &__img {
    width: 100%;
    max-width: 610px;
  }
}

@media screen and (max-width: 800px) {
  .rightFit {
    padding-top: 2rem;
    padding-bottom: 2rem;
    &__ttl {
      font-size: 40px;
      margin-bottom: 3rem;
    }
  }
  .benefits {
    grid-template-columns: 1fr;
    > div {
      background: #fcfcfd;
      border-radius: 16px;

      &:first-of-type,
      &:last-of-type {
        grid-column-start: unset;
        grid-column-end: unset;
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  .benefit {
    &__content {
      padding: 1rem;
    }
    &__tag {
      font-size: 12px;
    }
    &__ttl {
      font-size: 26px;
    }
    &__txt {
      font-size: 15px;
    }
  }
}
