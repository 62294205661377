.main {
  background: #fff;
  padding-top: 6.2rem;
}
.wrapper {
}
.header {
  margin-bottom: 2rem;
  padding-top: 4rem;
  max-width: 500px;

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;
  }
}
.contact {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 2rem;
  padding-bottom: 8rem;
}
.info {
  > div {
    margin-bottom: 2rem;
  }
  &__ttl {
    color: #101323;
    font-weight: 600;
    font-family: "Darker Grotesque";
    font-size: 18px;
    margin-bottom: 1rem;
  }

  &__txt {
    color: #5f6d7e;
    margin-bottom: 12px;
    font-size: 16px;
  }

  &__link {
    color: var(--secondary-blue);
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
  }

  &__socials {
    display: flex;
    gap: 1rem;

    a {
      border: 1px solid #eaecf0;
      box-shadow: 0px 1px 2px 0px #1018280a;
      border-radius: 6px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding-top: 3rem;

    &__ttl {
      font-size: 44px;
      margin-bottom: 1rem;
    }

    &__txt {
      font-size: 15px;
    }
  }
  .contact {
    grid-template-columns: 1fr;
    gap: 3rem;
    padding-bottom: 2rem;
  }
  .info {
    &__txt {
      font-size: 15px;
    }
  }
}
