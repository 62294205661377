.introBg {
  background: #fff;
  overflow: hidden;
}
.intro {
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  gap: 1rem;

  &__content {
  }

  &__tag {
    color: var(--secondary-blue);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 20px;
      path {
        fill: var(--secondary-blue);
      }
    }
  }

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    max-width: 400px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    max-width: 400px;
    line-height: 1.8;
    font-size: 18px;
  }

  &__img {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
  }
}

@media screen and (max-width: 800px) {
  .intro {
    grid-template-columns: 1fr;
    padding-bottom: 2rem;

    &__tag {
      font-size: 12px;
      svg {
        width: 16px;
      }
    }
    &__ttl {
      font-size: 40px;
    }
    &__txt {
      font-size: 15px;
      line-height: 1.5;
      max-width: unset;
    }
  }
}
@media screen and (max-width: 600px) {
  .intro {
    &__img {
      width: 150%;
      margin-left: -1rem;
    }
  }
}
