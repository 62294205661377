.modal {
  padding: 2rem;
  text-align: center;
  position: relative;
}
.close {
  position: absolute;
  width: 24px;
  right: 2rem;
  top: 2rem;
}
.ttl {
  color: var(--primary-blue);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.txt {
  color: var(--primary-blue);
  text-align: center;
  font-size: 15px;
  margin-bottom: 1.5rem;
  font-weight: 300;
}
.btn {
  width: 100%;
  padding: 8px;
  margin-top: 2rem;
}
