.modal {
}
.ttl {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #101323;
}
.txt {
  color: #667085;
  margin-bottom: 1.5rem;
}
.btnSec {
  display: flex;
  gap: 1rem;

  button {
    font-weight: 400;
    font-size: 14px;
    min-width: 150px;

    &.cancel {
      border-radius: 4px;
      border: 1px solid #d0d5dd;
      color: #101323;
      font-weight: 500;
    }

    &.submit {
      border-radius: 4px;
      background: var(--secondary-blue);

      &:hover {
        background: var(--primary-blue);
      }
    }
  }
}
