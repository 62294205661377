.wrapper {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.heading {
  margin-bottom: 3rem;

  &__tag {
    color: var(--secondary-blue);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 20px;
      path {
        fill: var(--secondary-blue);
      }
    }
  }

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    max-width: 600px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;

    span {
      color: var(--secondary-blue);
    }
  }
}

.testimonials {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding-top: 10rem;
}
.testimonial {
  border: 1px solid #d0d5dd;
  background: #f9fafb;
  border-radius: 12px;
  padding: 1.5rem 1rem;

  &:hover {
    box-shadow: 0px 0px 12px 0px #605bff80;
    border-color: #605bff;
  }

  &:nth-of-type(2),
  &:nth-of-type(5) {
    margin-top: -120px;
    height: fit-content;
  }

  &__ttl {
    color: #101323;
    font-family: "Darker Grotesque";
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 2rem;
  }

  &__txt {
    color: #667085;
    margin-bottom: 3.5rem;
    line-height: 1.5;
    font-size: 15px;
  }

  &__profile {
    // display: grid;
    // gap: 1rem;
    // grid-template-columns: 52px 1fr;
    font-size: 15px;

    img {
      width: 52px;
      border: 1px solid #eaecf0;
      border-radius: 50%;
      height: 52px;
      object-fit: cover;
    }
    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 4px 0;
    }
  }
  &__name {
    color: #101323;
  }
  &__org {
    color: #667085;
  }
}

@media screen and (max-width: 800px) {
  .heading {
    &__tag {
      font-size: 12px;
      svg {
        width: 16px;
      }
    }
    &__ttl {
      font-size: 40px;
    }
  }
  .testimonials {
    // display: flex;
    overflow-x: auto;
    padding-top: 0;
  }
  .testimonial {
    min-width: 250px;
    padding: 1rem;

    &__ttl {
      margin-bottom: 1rem;
      font-size: 14px;
    }

    &__txt {
      margin-bottom: 2rem;
      font-size: 14px;
    }

    &__profile {
      grid-template-columns: 40px 1fr;
      font-size: 14px;

      img {
        width: 40px;
        height: 40px;
      }
      > div {
        padding: 0;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(5) {
      margin-top: unset;
      height: auto;
    }
  }
}
