.wrapper {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.ttl {
  font-family: "Darker Grotesque";
  font-weight: 700;
  font-size: 44px;
  margin-bottom: 3rem;
  color: #101323;
}

.features {
  display: grid;
  gap: 1.2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.feature {
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  padding: 2rem;

  &:first-of-type {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  &:nth-of-type(2) {
    grid-column-start: 4;
    grid-column-end: 6;
  }

  &:nth-of-type(3) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  &:nth-of-type(4) {
    grid-column-start: 3;
    grid-column-end: 6;
  }

  &:nth-of-type(5) {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  &:nth-of-type(6) {
    grid-column-start: 4;
    grid-column-end: 6;
  }

  &__icon {
    box-shadow: 0px 1px 3px 0px #1018281a;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 1rem;
  }
  &__ttl {
    font-family: "Darker Grotesque";
    font-weight: 600;
    color: #101828;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &__txt {
    color: #667085;
    font-size: 18px;
    line-height: 1.5;
    max-width: 600px;
  }
}

@media screen and (max-width: 900px) {
  .wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .ttl {
    font-size: 36px;
    margin-bottom: 2rem;
  }
  .feature {
    padding: 1rem;

    &__icon {
      width: 32px;
      height: 32px;

      svg {
        width: 18px;
      }
    }
    &__ttl {
      font-size: 26px;
    }
    &__txt {
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 640px) {
  .features {
    grid-template-columns: 1fr;

    .feature {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }
}
