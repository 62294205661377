.modal {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  h1 {
    font-size: 18px;
  }

  svg {
    background: #f9fafb;
    padding: 2px;
    width: 24px;
    path {
      fill-opacity: 1;
      opacity: 1;
    }
  }
}
.notificationList {
  overflow: auto;
  padding-right: 12px;
  margin-right: -16px;
}
.notification {
  border-radius: 5px;
  background: var(--Greyscale-10, #f9fafb);
  margin-bottom: 1rem;
  padding: 12px 1rem;

  &--read {
    background: transparent;
  }

  &__ttl {
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 14px;
    color: #101323;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 22px;
  }

  &__btns {
    display: none;
    gap: 12px;

    &__read {
      width: 22px;
      path {
        stroke-width: 3px;
        stroke: var(--primary-blue);
      }
    }

    &__delete {
      width: 12px;
    }
  }

  &:hover {
    .notification__btns {
      display: flex;
    }
  }

  &__txt {
    color: #101323;
    margin-bottom: 1rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    p {
      color: #667085;
    }

    a {
      color: var(--secondary-blue);
      background: transparent;
      border: none;
      font-weight: 500;
      text-transform: capitalize;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.footer {
  margin-top: auto;
  border-top: 1px solid var(--Greyscale-30, #eaecf0);
  display: flex;
  justify-content: space-between;
  padding-top: 12px;

  &__clear {
    color: #e71d36;
    font-weight: 500;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      width: 12px;
    }
  }

  &__read {
    color: #101323;
    font-weight: 500;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 6px;

    svg {
      width: 20px;

      path {
        width: 20px;
        stroke: #101323;
        stroke-width: 2px;
      }
    }
  }
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    width: 50%;
    margin-bottom: 1.5rem;
  }
}
