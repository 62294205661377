.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }

  .txt {
    margin-bottom: 12px;
  }

  .skipBtn {
    padding: 4px 1.8rem;
    font-size: 14px;
    border-radius: 8px;
    color: var(--secondary-blue);
  }
}
.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 1.5rem;
  padding-top: 1rem;
}
.locationWrap {
  position: relative;

  &__label {
    display: flex;
    align-items: center;

    p {
      font-size: 15px;
      background: transparent;
      padding: 0;
      color: var(--primary-blue);
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  .toggle {
    transition: 0.3s;
    display: inline-block;
    height: fit-content;
    margin-left: 2rem;
  }
}
.salaryWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 1.5rem;
  position: relative;

  > label {
    grid-column-start: 1;
    grid-column-end: 3;
    font-size: 15px;
    background: transparent;
    padding: 0;
    color: var(--primary-blue);
    // margin-bottom: 10px;
    font-weight: 600;
  }

  > div {
    margin-bottom: 0;
  }

  .currency [class*="formSelect__value-container"] {
    padding-left: 10px;
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}
.qualityList {
  display: flex;
  flex-wrap: wrap;
  span {
    border-radius: 6px;
    border: 1px solid rgba(96, 91, 255, 0.5);
    background: #fbfbff;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 4px 8px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      margin-right: -4px;
      margin-left: 2px;
      path {
        fill: var(--secondary-blue);
        fill-opacity: 1;
        opacity: 1;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .form {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}
