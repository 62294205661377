.container {
  background: #fff;
  border-radius: 8px;
  padding: 1rem;
}
.dateFilter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  label {
    margin-left: 12px;
    width: 130px;

    span {
      font-size: 12px;
      color: #808080;
      margin-bottom: 4px;
      display: block;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid var(--primary-blue);
      border-radius: 6px;
      padding: 6px;
      background: transparent;
      padding-right: 6px;
    }
  }

  button {
    padding: 8px 12px;
    margin-top: auto;
    font-size: 12px;
    border-radius: 6px;
    margin-left: 12px;

    &.reset {
      border-color: red;
      color: red;
    }
  }
}
.tableRow,
.tableHeader {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 100px;
  gap: 1rem;
  text-align: center;

  > span:first-of-type {
    text-align: left;
  }
}

@media screen and (max-width: 600px) {
  .tableHeader {
    display: none;
  }
  .tableRow {
    grid-template-columns: 1fr;
    text-align: left;
    gap: 10px;
    padding: 1rem;
  }
}
