.info {
  position: relative;
  display: flex;
  align-items: center;

  &:hover {
    div {
      display: block;
    }
  }
  div {
    display: none;
    position: absolute;
    color: #667085;
    width: 240px;
    background: #fff;
    padding: 12px;
    border-radius: 10px;
    right: -8px;
    box-shadow: 0 2px 5px 1px rgb(0 0 0 /15%);
    top: 36px;
    z-index: 25;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      position: absolute;
      top: -6px;
      right: 12px;
      background: #fff;
    }

    p:first-of-type {
      color: #101323;
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 6px;
    }
  }
}
.middle {
  div {
    left: -120px;

    &::before {
      top: -6px;
      left: 124px;
      right: 0;
    }
  }
}
.right {
  div {
    left: -8px;

    &::before {
      top: -6px;
      left: 12px;
      right: 0;
    }
  }
}
