.pagination {
  border-top: var(--border-1);
}
.name {
  position: relative;

  span {
    position: absolute;
    color: var(--secondary-blue);
    font-size: 13px;
    margin-left: 3px;
    margin-top: -4px;
  }
}
.status {
  text-transform: capitalize;
  border-radius: 24px;
  width: fit-content;
  font-weight: 500;
  padding: 6px 10px;
  text-align: center;
  min-width: 70px;
  display: block;
  margin: auto;

  &.active {
    color: #149b32;
    background: #3a974c10;
  }

  &.scheduled {
    color: #f29339;
    background: #f2933910;
  }

  &.closed {
    color: #d11a2a;
    background: #d11a2a10;
  }
}

.tableBodyRow {
  .label {
    display: none;
  }
}
.emailWrap {
  overflow: hidden;
  a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

.copyBtn {
  color: #012169;
  border-radius: 10px;
  background: rgba(39, 133, 229, 0.1);
  border: none;
  outline: none;
  padding: 10px 1.2rem;
  white-space: nowrap;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  display: block;

  svg {
    display: none;
  }
}
.viewBtn {
  border-radius: 22.5px;
  border: 1px solid #00000010;
  background: #605bff10;
  color: #605bff;
  padding: 6px 18px;
  font-weight: 500;
  display: flex;
  width: fit-content;
  margin: auto;

  svg {
    display: none;
  }
}

.scoreWrap {
  position: relative;
  width: fit-content;
  height: fit-content;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 110%;

    &::after {
      content: "%";
    }

    &.green {
      color: #149b32;
    }

    &.orange {
      color: #f29339;
    }

    &.red {
      color: #d11a2a;
    }

    &.blue {
      color: #6361f9;
    }
  }
  .score {
    width: 50px;
    height: 50px;
  }
}
.check {
  width: 16px;
  height: 16px;
  accent-color: var(--secondary-blue);
}
.dateBreakdown {
  span:last-of-type {
    margin-left: 10px;
  }

  &::after {
    content: " (GMT)";
  }
}

@media screen and (max-width: 900px) {
  .tableBodyRow {
    margin-bottom: 1rem;
    z-index: unset !important;
    box-shadow: 1px 4px 6px 0px rgba(3, 2, 41, 0.07);

    .label {
      display: block;
      font-weight: 400;
      color: #00000060;
      margin-bottom: 4px;
      font-size: 14px;
    }

    span {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .status {
    margin-left: 0;
    grid-column-start: 1;
  }
  .copyLinkSec {
    button {
      margin: 0;
    }
  }

  .viewBtn {
    background: transparent;
    padding: 0;
    border: none;
    font-weight: 400;
    align-items: center;
    text-decoration: underline;

    svg {
      display: block;
      margin-left: 4px;
      width: 14px;
      height: 14px;

      path {
        stroke: #605bff;
      }
    }
  }

  .copyBtn {
    background: transparent;
    text-decoration: underline;
    display: flex;
    align-items: center;
    padding: 0;

    svg {
      display: block;
      margin-left: 4px;
    }
  }
  div.tableBodyRow {
    grid-template-columns: 1fr 1fr;
    text-align: left;
    position: relative;
  }
  .date {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .scoreWrap {
    grid-column-start: 2;
    grid-row-start: 1;
    margin-left: auto;
  }
  .title {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .rightAligned {
    // text-align: right;
  }
  .viewBtn {
    margin-left: 0;
  }
  .checkWrap {
    grid-column-end: 3;
    grid-column-start: 1;
  }
  .scoreWrap {
    grid-row-start: 2;
  }
}

@media screen and (max-width: 600px) {
  div.tableBodyRow {
    padding: 1rem;
  }
  .label {
    white-space: nowrap;
  }
}
