.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }

  .txt {
    margin-bottom: 12px;
  }

  .skipBtn {
    padding: 4px 1.8rem;
    font-size: 14px;
    border-radius: 8px;
    color: var(--secondary-blue);
  }
}
.form {
  padding-top: 1rem;

  .ttl {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .txt {
    font-size: 15px;
    margin-bottom: 1rem;
    font-style: italic;
    font-weight: 300;
  }
}

.factors {
  margin-bottom: 3rem;

  .factorSet {
    max-width: 600px;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  .factor {
    padding: 10px;
    display: block;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);

    &.disable {
      opacity: 0.6;
      cursor: not-allowed;
    }

    input {
      margin-right: 6px;
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    span {
      display: block;
      margin-left: 20px;
      overflow: hidden;
      word-break: break-word;
      font-weight: 300;
    }
  }
}

.qualities {
  margin-bottom: 3rem;
  align-items: center;

  .ttl {
    margin-right: 1rem;
    white-space: nowrap;
  }

  > div {
    max-width: 400px;
  }
}

.demands {
  ol {
    padding-left: 1rem;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      justify-content: space-between;
    }
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}
.qualityList {
  display: flex;
  flex-wrap: wrap;
  span {
    border-radius: 6px;
    border: 1px solid rgba(96, 91, 255, 0.5);
    background: #fbfbff;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 4px 8px;
    font-weight: 500;
    text-transform: capitalize;
    display: flex;
    align-items: center;

    svg {
      width: 20px;
      margin-right: -4px;
      margin-left: 2px;
      path {
        fill: var(--secondary-blue);
        fill-opacity: 1;
        opacity: 1;
      }
    }
  }
}
.error {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.red {
  color: #e74444;
}
@media screen and (max-width: 600px) {
  .demands {
    ol li {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.5rem;

      > div {
        margin-left: 12px;
      }

      span {
        margin-bottom: 10px;
      }
    }
  }
}
