.ttl {
  color: #101323;
  font-family: "Darker Grotesque";
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 10px;
}
.txt {
  margin-bottom: 2rem;
  font-weight: 400;
  color: #667085;
  font-size: 15px;
  text-align: left;
}
.btn {
  margin-top: 1.5rem;
  width: 100%;
  border-radius: 4px;
  font-weight: 400;
}
.backBtn {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  margin-top: 1rem;
  color: #101323;
  color: #605bff;
}
.success {
  p {
    font-size: 14px;
    line-height: 1.6;
    color: #667085;
  }

  // .resendBtn {
  //   padding: 0;
  //   font-size: 14px;
  //   font-weight: 400;
  //   display: inline-block;
  //   color: var(--secondary-blue);
  // }
}
