.legend {
  display: grid;
  gap: 6px;

  &__item {
    border-left: 4px solid;
    padding: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    font-size: 15px;

    &--green {
      border-color: #18b368;
      background: #e6fff3;
    }
    &--blue {
      border-color: #605bff;
      background: #e5e1fc;
    }
    &--orange {
      border-color: #eda12f;
      background: #fff5e6;
    }
    &--red {
      border-color: #e62e2e;
      background: #ffe6e6;
    }

    p {
      color: #667085;
      span {
        color: #101323;
      }
    }
  }
}
