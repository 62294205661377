.modal {
  padding: 2rem;
}
.steps {
  display: flex;
  width: fit-content;
  margin: 0 auto 2rem;

  span {
    border: 1px solid var(--primary-blue);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--primary-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;

    &.active {
      background: var(--primary-blue);
      color: #fff;
    }
    &.complete {
      border-color: #149b32;
      background: #fff;
    }
  }

  span:nth-of-type(2) {
    margin-left: 80px;
    position: relative;

    &::before {
      content: "";
      height: 2px;
      width: 80px;
      display: block;
      background: #33333380;
      position: absolute;
      right: 39px;
    }
  }
}

.ttl {
  color: var(--primary-blue);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 2rem;
}
.btn {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  width: 100%;
}
.phoneTxt {
  color: var(--primary-blue);
  margin-top: -1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}
.employees {
  label {
    font-size: 15px;
    background: transparent;
    padding: 0;
    color: var(--primary-blue);
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
  }

  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    button {
      border-radius: 6px;
      border: 1px solid rgba(250, 250, 251, 0.5);
      background: #f9f9f9;
      box-shadow: 0px 1.1061320304870605px 3.3183960914611816px 0px
        rgba(0, 0, 0, 0.04);
      color: #000;
      margin-right: 1rem;
      padding: 12px 10px;
      font-size: 11px;
      word-break: break-all;

      span {
        font-weight: 500;
        display: block;
        font-size: 13px;
        margin-bottom: 4px;
      }

      &.selected {
        background: var(--secondary-blue);
        color: #fff;
        border: 1px solid rgba(250, 250, 251, 0.5);
        box-shadow: 0px 1.1061320304870605px 3.3183960914611816px 0px
          rgba(0, 0, 0, 0.04);
      }
    }
  }
}
.errorMsg {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
