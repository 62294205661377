.howBg {
  background: #fff;
}
.how {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
}
.content {
  &__tag {
    color: var(--secondary-blue);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 20px;
      path {
        fill: var(--secondary-blue);
      }
    }
  }

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 44px;
    font-weight: 700;
    max-width: 450px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;

    span {
      color: var(--secondary-blue);
    }
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    max-width: 450px;
    line-height: 1.8;
    font-size: 18px;
  }
}
.benefits {
  max-height: 560px;
  overflow: auto;
  display: grid;
  gap: 2rem;
  padding-right: 8%;

  &::-webkit-scrollbar {
    // width: 6px;
    background: #f9fafb;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f9fafb;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-blue);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-blue);
  }
}
.benefit {
  background: #f9fafb;
  border-radius: 12px;

  &__content {
    padding: 2rem;
  }
  &__tag {
    text-transform: uppercase;
    color: var(--secondary-blue);
  }
  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 30px;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  &__txt {
    color: #667085;
    font-size: 18px;
    line-height: 1.6;
  }
  &__img {
    width: 100%;
    max-width: 610px;
  }
}

@media screen and (max-width: 900px) {
  .how {
    grid-template-columns: 1fr;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .content {
    margin-bottom: 2rem;

    &__tag {
      font-size: 12px;
      svg {
        width: 16px;
      }
    }
    &__ttl {
      font-size: 32px;
      margin-bottom: 1rem;
    }
    &__txt {
      font-size: 15px;
      line-height: 1.5;
    }
  }
  .benefits {
    padding-right: 0;
    max-height: unset;
  }
  .benefit {
    &__content {
      padding: 2rem 1rem;
    }
    &__img {
      margin: auto;
      display: block;
    }
    &__ttl {
      font-size: 26px;
    }
    &__txt {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}
