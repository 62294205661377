.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 210px);
  justify-content: center;
  min-height: 400px;

  img {
    width: 65%;
    max-width: 300px;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 20px;
    font-style: normal;
    margin-bottom: 10px;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    max-width: 350px;

    span {
      color: rgba(0, 0, 0);
      font-weight: 600;
    }
  }
}

.feedback {
  padding: 2rem 0;

  &__ttl {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1.5rem;
  }

  &__label {
    font-weight: 500;
    margin-bottom: 1rem;
  }
}
.submitBtn {
  width: 100%;
}

.scaleWrap > section {
  display: flex;

  p {
    min-width: 40px;
    width: 100%;
  }

  fieldset {
    label {
      span {
        margin: 0 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .scaleWrap > section {
    display: grid;

    fieldset {
      label {
        span {
          margin: 0;
        }
      }
    }
  }
}
