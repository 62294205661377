.pagination {
  border-top: var(--border-1);
}

.status {
  text-transform: capitalize;
  border-radius: 24px;
  width: fit-content;
  font-weight: 500;
  padding: 6px 10px;
  text-align: center;
  min-width: 70px;
  display: block;
  margin: auto;

  &.active {
    color: #149b32;
    background: #3a974c10;
  }

  &.scheduled {
    color: #f29339;
    background: #f2933910;
  }

  &.closed,
  &.paused {
    color: #d11a2a;
    background: #d11a2a10;
  }
}
.menuSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.tableBodyRow {
  .label {
    display: none;
  }
}
.date {
  span:last-of-type {
    margin-left: 10px;
  }

  &::after {
    content: " (GMT)";
  }
}
.mobileMenu {
  display: none;
}
@media screen and (max-width: 900px) {
  .tableBodyRow {
    grid-template-columns: 1fr 1fr;
    .label {
      display: block;
      font-weight: 400;
      color: #00000060;
      margin-bottom: 4px;
      font-size: 14px;
    }

    span {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .status {
    margin-left: 0;
    grid-column-start: 1;
  }
  .statusSec {
    position: absolute;
    right: 16px;
    top: 13px;
    font-size: 13px;

    P {
      background: transparent !important;
      padding: 0;
      min-width: unset;
    }
  }

  .date {
    &::after {
      display: none;
    }
  }
  .startDate {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .endDate {
    grid-column-start: 2;
    grid-column-end: 3;
    text-align: right;
  }

  .menuSec {
    grid-column-end: 3;
    grid-column-start: 1;
    justify-content: flex-start;

    > div:last-of-type {
      // display: none;
      margin-left: auto;
    }
  }
  .title {
    grid-column-end: 3;
    grid-column-start: 1;
  }
  .mobileMenu {
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
      border-radius: 10px;
      background: rgba(39, 133, 229, 0.1);
      color: #101323;
      display: flex;
      align-items: center;
      border: none;
      padding: 10px 1.5rem;
      white-space: nowrap;
      font-weight: 500;
      gap: 10px;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      svg {
        width: 18px;

        path {
          fill: #101323;
        }
      }
    }

    a {
      width: 18px;
      height: 18px;
    }

    svg {
      width: 18px;
    }
  }
}
