.container {
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}
.heading {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 1rem;

  &__ttl {
    color: #101828;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
}
.body {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.credits {
  display: flex;
  gap: 1rem;

  div {
    border: 1px solid var(--secondary-blue);
    padding: 12px 16px;
    border-radius: 6px;

    p {
      &:last-of-type {
        font-size: 28px;
        font-weight: 500;
        margin-top: 6px;
        text-align: center;
      }
    }

    &:first-of-type {
      background: var(--secondary-blue);
      color: #fff;
    }
    &:last-of-type {
    }
  }
}
.info {
  &__txt1 {
    color: #101828;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 4px;
  }

  &__txt2 {
    color: #667085;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  button {
    border: 1px solid var(--gray-300, #d0d5dd);
    background: var(--White, #fff);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px;
    color: #344054;
    border-radius: 8px;
    width: 100%;
    max-width: 200px;
    /* margin-left: auto; */
    display: block;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
@media screen and (max-width: 800px) {
  .container {
    padding: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .info {
    button {
      max-width: unset;
    }
  }
}
