.filterSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;

  .selectWrap {
    margin: 0;
    z-index: 15;
    max-width: 130px;

    label {
      font-size: 12px;
      color: #808080;
      margin-bottom: 4px;
      display: block;
      font-weight: 400;
    }
  }
  .select {
    [class*="formSelect__control"] {
      border-color: var(--primary-blue);
      height: 33px;
      border-radius: 4px;
    }

    [class*="formSelect__single-value"] {
      color: var(--primary-blue);
    }
    [class*="formSelect__input-container"] {
      height: 100%;
    }
    [class*="formSelect__value-container"] {
      padding-left: 4px;
    }
  }
}

.tableRow,
.tableHeader {
  display: grid;
  grid-template-columns: 1.5fr 1.5fr repeat(3, 1fr) repeat(2, 0.6fr) 28px;
  gap: 1rem;
  text-align: center;

  span:first-of-type {
    text-align: left;
  }
}

.tableRow,
.tableHeader {
  span:first-of-type,
  span:nth-of-type(2) {
    font-weight: 500;
  }
}

.dateFilter {
  display: flex;
  justify-content: flex-end;

  label {
    margin-left: 12px;
    max-width: 130px;
    width: 100%;

    span {
      font-size: 12px;
      color: #808080;
      margin-bottom: 4px;
      display: block;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid var(--primary-blue);
      border-radius: 4px;
      padding: 6px;
      background: transparent;
      padding-right: 6px;
    }
  }

  button {
    padding: 8px 12px;
    margin-top: auto;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 12px;

    &.reset {
      border-color: red;
      color: red;
    }
  }
}

@media screen and (max-width: 900px) {
  .tableHeader {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .filterSec {
    flex-wrap: wrap;

    .selectWrap {
      width: 100%;
      max-width: unset;
      margin-bottom: 1rem;
    }
  }
  .dateFilter {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    label {
      max-width: unset;
      margin-left: 0;
    }
  }
}
