.modal {
  max-width: 550px;
  padding: 2rem 1.5rem;
}
.header {
  margin-bottom: 1.5rem;

  &__ttl {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 4px;
    color: #101323;
  }

  &__txt {
    color: #667085;
    a {
      color: var(--secondary-blue);
      text-decoration: underline;
      margin: 0 5px;
    }
  }
}

.form {
}

.fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr 13px;
  align-items: center;
  gap: 1rem;

  svg {
    width: 13px;
  }
}
.inputWrap {
  margin-bottom: 1rem;

  label {
    color: #667085;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 6px;
  }

  input {
    border-color: #d0d5dd;
    background: transparent;
  }
}

.ctaSec1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 0.5rem;

  button {
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;

    &.newCandidate {
      color: #98a2b3;
    }

    &.bulkInvite {
    }
  }
}

.ctaSec2 {
  display: flex;
  gap: 1rem;

  button {
    font-weight: 400;
    font-size: 14px;
    min-width: 150px;

    &.cancel {
      border-radius: 4px;
      border: 1px solid #d0d5dd;
      color: #101323;
      font-weight: 500;
    }

    &.submit {
      border-radius: 4px;
      background: var(--secondary-blue);

      &:hover {
        background: var(--primary-blue);
      }
    }
  }
}

.bulk {

  &__upload {
    border-radius: 16px;
    border: 1px dashed var(--Greyscale-60, #667085);
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 240px;
    color: #667085;
    gap: 1.2rem;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  &__error {
    font-size: 12px;
    color: #e74444;
    display: block;
    margin-bottom: 2rem;
    margin-top: -1.6rem;
  }
}

.links {
  margin-bottom: 1.5rem;
  &__ctas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;

    button,
    a {
      border-radius: 6px;
      border: 1px solid var(--Grayscale-40, #d0d5dd);
      background: var(--Greyscale-White, #fff);
      color: #667085;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      gap: 8px;
      font-size: 14px;

      svg {
        width: 20px;
      }
    }
  }

  &__txt {
    color: #98a2b3;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 1.5rem;

    &::after {
      content: "";
      height: 1px;
      width: 100%;
      background: #eaecf0;
      display: block;
    }
  }
}

@media screen and (max-width: 600px) {
  .fieldset {
    gap: 10px;
  }
}
