.container {
  display: flex;
  padding: 5rem 0;
  min-height: 100vh;
}
.content {
  padding: 3rem 4%;
  text-align: center;
  background: #fff;
  max-width: 450px;
  margin: auto;
  border-radius: 2px;
  width: 95%;

  svg {
    width: 160px;
    margin-bottom: 1rem;
  }
}
.logo {
  width: 160px;
  height: auto;
  position: absolute;
  right: 2rem;
  top: 2rem;
}
.ttl {
  color: var(--primary-blue);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.txt {
  color: var(--primary-blue);
  text-align: center;
  font-size: 15px;
  margin-bottom: 1.5rem;
  font-weight: 300;
}
.btn {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #fff;
  background: var(--secondary-blue);
  border-color: var(--secondary-blue);
  display: block;
  padding: 12px;
  border-radius: 6px;
}

@media screen and (max-width: 800px) {
  .logo {
    left: 1rem;
    right: unset;
    top: 1rem;
    width: 140px;
  }
}
