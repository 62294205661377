.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 210px);
  justify-content: center;

  img {
    width: 65%;
    max-width: 300px;
    margin-bottom: 2rem;
  }

  h1 {
    font-size: 20px;
    font-style: normal;
    margin-bottom: 10px;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    max-width: 350px;

    span {
      color: rgba(0, 0, 0);
      font-weight: 600;
    }
  }
}
