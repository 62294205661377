.menu {
  position: relative;

  &__cta {
  }

  &__options {
    position: absolute;
    border-radius: 6px;
    background: var(--Greyscale-White, #fff);
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    padding: 8px 0;
    right: 0;

    button {
      color: #667085;
      display: flex;
      gap: 12px;
      white-space: nowrap;
      align-items: center;
      background: transparent;
      border: none;
      padding: 8px 1rem;
      padding: 8px 1.2rem;
      width: 100%;

      a {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      svg {
        width: 20px;
      }

      &:hover {
        background: #fafbfc;
      }
    }
  }
}
