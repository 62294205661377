/* FAQ Item */
.faqItem {
  border-bottom: 1px solid #eaecf0;

  * {
    transition: 0.3s;
  }
}
.faqBtn {
  width: 100%;
  text-align: left;
  padding: 1.3rem 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #101323;
}
.open {
  padding: 1rem 0 12px;
}
.faqBtn span {
  width: 80%;
  line-height: 1.7;
  color: #1b1f27;
}
.faqBtn svg {
  path {
    stroke: #101323;
  }
}
.faqBody {
  padding: 0;
  line-height: 1.6;
  font-size: 18px;
  width: 93%;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
  color: #667085;
}
.activeItem .faqBody {
  height: auto;
}
.inactiveItem .faqBody {
  animation-name: hideFaq;
}
.faqBody div {
  margin-bottom: 1.7rem;
}
.up {
  transform: rotate(90deg);
}
.down {
  transform: rotate(-90deg);
}

@media screen and (max-width: 800px) {
  .faqBtn {
    font-size: 15px;
  }
  .faqBody {
    font-size: 15px;
  }
}
