.question {
  font-weight: 600;
  margin-bottom: 2rem;
  font-size: 16px;

  span {
    font-size: 12px;
    display: block;
    font-weight: 400;
    margin-top: 6px;
  }
}
.questionWrap {
  margin-bottom: 6rem;
}
.nextBtn {
  margin: auto;
  width: 220px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5rem;
  border-radius: 10px;
  height: 50px;
}
.scaleWrap > p {
  font-weight: 400;
}
.errorMessage {
  font-size: 14px;
  color: #e74444;
  display: block;
  margin-top: -1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 800px) {
  .questionWrap {
    margin-bottom: 3.5rem;
  }
  .question {
    margin-bottom: 1.5rem;
    font-size: 14px;
  }
}