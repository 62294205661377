.wrapper {
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.ttl {
  font-family: "Darker Grotesque";
  font-weight: 700;
  font-size: 44px;
  margin-bottom: 3rem;
  color: #101323;
  text-align: center;
}
.faqList {
  max-width: 760px;
  margin: auto;
  margin-bottom: 3rem;
}
.contact {
  color: #667085;
  font-size: 18px;
  text-align: center;

  a {
    color: var(--secondary-blue);
  }
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: 36px;
    text-align: left;
    font-weight: 600;
    margin-bottom: 1.5rem;
    line-height: 1.2;
  }
  .contact {
    text-align: left;
    font-size: 15px;
  }
}
