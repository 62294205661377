.wrapper {
  background: #fff;
  padding-top: 6.2rem;
}

.header {
  margin-bottom: 7rem;
  padding-top: 6rem;

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 600;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1rem;
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;
  }
}
.body {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 2rem;
  align-items: flex-start;
}
.section {
  margin-bottom: 2rem;
  &__ttl {
    color: #101323 !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    margin-bottom: 4px !important;
  }

  &__subTtl {
    color: #101323 !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    margin-bottom: 4px !important;
  }

  p {
    color: #667085;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  ul {
    color: #667085;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 2rem;
    padding-left: 2rem;

    p {
      margin-bottom: 0;
      margin-left: -2rem;
    }
  }
}
.nav {
  position: sticky;
  top: 100px;
  padding-left: 1rem;
  padding-top: 1rem;
  overflow: auto;
  max-height: 920px;
  height: calc(100vh - 120px);

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::before {
    content: "";
    width: 2px;
    height: calc(100% - 2rem);
    display: block;
    position: absolute;
    background: #f2f4f7;
    left: 0;
    margin-top: 2rem;
    height: 880px;
    top: 0;
    margin-left: 6px;
  }
  &__arrow {
    transform: rotate(-90deg);
    margin-left: -1.2rem;

    path {
      stroke: #101323;
    }
  }
  button {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
    color: #667085;
    margin-bottom: 2rem;
    text-align: left;
    font-size: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media screen and (max-width: 800px) {
  .header {
    padding-top: 3rem;
    margin-bottom: 4rem;
    &__ttl {
      font-size: 44px;
      margin-bottom: 1rem;
    }

    &__txt {
      font-size: 15px;
    }
  }
  .nav {
    &::before {
        height: 580px;
    }
    button {
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
  .section {
    &__ttl {
      font-size: 20px !important;
    }
    &__subTtl {
      font-size: 16px !important;
    }
    p {
      font-size: 15px;
      margin-bottom: 1.5rem;
    }
    ul {
      font-size: 15px;
      margin-bottom: 1.5rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .nav {
    display: flex;
    align-items: center;
    overflow-x: auto;
    gap: 1rem;
    background: #fff;
    top: 66px;
    padding: 1rem;
    display: none;

    &::before {
      display: none;
    }

    button {
      margin-bottom: 0;
      width: fit-content;
      min-width: fit-content;
      display: block;
    }
  }
  .body {
    grid-template-columns: 1fr;
  }
}
