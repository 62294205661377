.wrapper {
  padding: 2rem 1rem 1rem 0;
}
.container {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 1.5rem;
  padding-top: 1rem;
  align-items: flex-start;

  &--observative {
    grid-template-columns: 1fr;
    padding-left: 1rem;
  }
}
.sec1 {
  display: grid;
  gap: 4rem;

  &__expectation {
    > p {
      color: #667085;
      margin-left: 1rem;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
}

.qualities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem 2rem;

  &__nav {
    display: none;
  }

  &__item {
    border-radius: 8px;
    background: #fff;
    line-height: 1.6;
    padding-bottom: 10px;
    color: #667085;

    &__ttl1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      letter-spacing: -0.2px;
      margin-bottom: 2px;

      span {
        display: flex;
        gap: 6px;
        font-weight: 500;

        svg {
          width: 16px;
        }
      }

      &__red {
        color: #e62e2e;
      }

      &__green {
        color: #18b368;
      }
    }

    &__ttl2 {
      font-size: 20px;
      margin-bottom: 4px;

      &--red {
        color: #e62e2e;
      }

      &--green {
        color: #18b368;
      }
    }

    > div {
      margin-bottom: 1rem;
    }

    &__listTtl {
      font-weight: 600;
      margin-bottom: 2px;
    }

    ul {
      padding-left: 1rem;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
    gap: 3rem;

    &--observative {
      padding-left: 0;
    }
  }
  .sec1 {
    gap: 1.5rem;
  }
  .wrapper {
    padding-left: 1rem;
  }
  .qualities {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
