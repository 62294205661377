.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  p {
    b {
      font-weight: 500;
    }
  }

  div {
    display: flex;
    button {
      padding: 8px 12px;
      margin-left: 1.2rem;
      width: 40px;
      height: 40px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &:last-of-type {
        svg {
          transform: rotate(180deg);
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
