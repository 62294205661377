.wrapper {
  padding: 2rem 1rem 1rem 0;
}
.container {
  display: grid;
  grid-template-columns: 240px 1fr;
  gap: 1.5rem;
  padding-top: 1rem;
}

.table {
  font-size: 14px;

  &__heading {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 0.6fr;
    font-weight: 600;
    padding: 1rem;
    gap: 6px;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 0.6fr;
    padding: 1rem;
    gap: 6px;

    p:first-of-type {
      font-weight: 600;
    }

    .result {
      display: flex;
      align-items: center;

      svg {
        width: 18px;
        height: 18px;
        margin-left: 10px;
      }
      &.conflict {
        color: #e74444;
        font-weight: 500;
      }

      &.match {
        color: #149b32;
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
  }
  .wrapper {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .table {
    font-size: 14px;
    padding-top: 0;

    &__heading {
      padding: 1rem 0;
      grid-template-columns: repeat(4, 1fr);
    }
    &__body {
      padding: 1rem 0;
      align-items: center;
      grid-template-columns: repeat(4, 1fr);

      .result {
        &.match,
        &.conflict {
          // font-size: 13px;
          svg {
            margin-left: 4px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}
