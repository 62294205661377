.wrapper {
  padding-top: 4rem;
  padding-bottom: 3rem;
}
.header {
  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    max-width: 600px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1rem;
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    margin-bottom: 2rem;
    font-size: 18px;
    letter-spacing: normal;
    line-height: 1.5;
  }
}
.infoSec {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 7%;
  max-width: 1030px;
  margin: 0 auto;
  padding: 4rem 0;
  align-items: center;
}
.content {
  &__ttl {
    font-family: "Darker Grotesque";
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 1.5rem;
  }
  &__item {
    display: flex;
    gap: 12px;
    margin-bottom: 1.5rem;

    div {
      border: 1px solid #eaecf0;
      border-radius: 6px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      min-width: 30px;
      justify-content: center;

      svg {
        width: 18px;
      }
    }
    p {
      color: #667085;
      font-size: 18px;
      line-height: 1.5;

      span {
        color: #101323;
      }
    }
  }
}
.gettingStartedImg {
  width: 100%;
  background: #ffc94a6b;
  border-radius: 32px;
  text-align: center;
  padding: 2.5rem 2rem 0;
  display: flex;
  flex-direction: column;

  &__txt {
    color: #dc6803;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 18px;
    font-style: italic;
    span {
      color: #101828;
    }
  }

  &__avatars {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: center;
    gap: 10%;
    img {
      width: 40%;
    }
    svg {
      width: 15%;

      &:last-of-type {
        transform: scaleX(-1);
      }
    }
  }
  &__img {
    width: 100%;
    display: block;
  }
}
.assessmentDetailsImg {
  width: 100%;
  background: linear-gradient(140deg, #b5ffde, #b5ffde);
  border-radius: 32px;
  text-align: center;
  padding: 6rem 2rem 0;
  display: flex;
  flex-direction: column;

  &__img {
    width: 100%;
    display: block;
  }
}
.supportImg {
  width: 100%;
  background: linear-gradient(140deg, #b7b5ff, #ebebff);
  border-radius: 32px;
  text-align: center;
  padding: 0 2rem 2rem;

  img {
    width: 100%;
    max-width: 380px;
    display: block;
    margin: auto;
  }

  p {
    font-size: 38px;
    font-weight: 700;
    line-height: 1.2;
    font-family: "Darker Grotesque";
    margin-top: -2.5rem;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .header {
    &__ttl {
      font-size: 44px;
    }

    &__txt {
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }
  .infoSec {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &:first-of-type,
    &:last-of-type {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;
    }
  }
  .content {
    &__ttl {
      font-size: 24px;
    }
    &__item {
      div {
        width: 24px;
        height: 24px;
        min-width: 24px;

        svg {
          width: 16px;
        }
      }
      p {
        font-size: 15px;
      }
    }
  }
  .gettingStartedImg {
    padding: 2rem 1.5rem 0;
    &__txt {
      font-size: 15px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    &__avatars {
      margin-bottom: 4rem;
      width: calc(100% + 2.8rem);
      margin-left: -1.4rem;
      gap: 1rem;

      svg {
        margin-top: -4rem;
        width: 18%;
      }
      img {
        width: 48%;
      }
    }
  }
  .assessmentDetailsImg {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .supportImg {
    padding-top: 2rem;
    p {
      font-size: 32px;
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    &__ttl {
      font-size: 36px;
    }
  }
}
