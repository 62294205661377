.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 2rem;
  width: 100%;
}
.question {
  font-size: 15px;
  background: transparent;
  padding: 0;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 600;
  white-space: break-spaces;
}
.message {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.label {
  margin-bottom: 1.2rem;
  flex-wrap: wrap;
}
.optionWrap {
  display: flex;
  align-items: center;
}
.input {
  accent-color: var(--secondary-blue);
  width: 24px;
  height: 24px;
  margin-right: 1.5rem;

  &:disabled {
    cursor: not-allowed;
  }
}

.error .question {
  color: #e74444;
}
.error .input {
  border-color: #e74444;
}
.req {
  color: #e74444;
  font-weight: 600;
}

.ratingSec {
  width: 100%;
  margin-left: 2.3rem;
  margin-top: 10px;
}
.instruction {
  font-size: 13px;
  font-weight: 300;
  margin-top: 4px;
}

.imgWrap {
  margin-bottom: 2rem;
  img {
    height: 240px;
    border-radius: 4px;
    object-fit: contain;
    background: #fafbfc;
    max-width: 500px;
  }

  p {
    font-size: 13px;
    font-weight: 300;
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .input {
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-right: 1rem;
  }
  .label {
    margin-bottom: 1rem;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
}
