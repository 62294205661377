.container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 800px;
}
.header {
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.ttl {
  text-align: center;
  font-size: 28px;
}
.txt {
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  margin: 6px 0;
}
.info {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
  font-size: 18px;

  p {
    width: 240px;

    span:first-of-type {
      font-weight: 600;
      margin-right: 6px;
    }

    &:nth-of-type(even) {
      margin-left: auto;
    }
  }
}
.descrip {
  white-space: break-spaces;
  font-size: 18px;
  margin: 2rem auto;
  font-weight: 300;
}
.instructions {
  font-size: 18px;
  font-weight: 300;

  ol {
    padding-left: 1rem;
    margin: 6px 0 1.5rem;
    line-height: 1.5;
  }
}
.areas {
  text-align: center;
  margin: 2rem 0;
  font-size: 18px;
  font-weight: 300;
}
.sections {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  text-transform: capitalize;
  justify-content: center;
  margin: 2rem 0 3rem;

  span {
    border-radius: 20px;
    border: 1px solid #000;
    background: rgba(80, 200, 120, 0.2);
    padding: 6px 12px;
    margin: 0 10px 1rem;
  }
}
.btn {
  font-weight: 400;
  width: 200px;
  font-size: 17px;
  margin: auto;
  border-radius: 10px;
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: 22px;
  }
  .txt {
    font-size: 16px;
    font-weight: 400;
  }
  .info {
    font-size: 14px;
    padding: 0 1rem;

    p {
      width: unset;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-right: auto;
      min-width: 100px;

      span {
        width: 100%;
      }

      &:nth-of-type(even) {
        margin-right: 0;
      }
    }
  }
  .descrip,
  .instructions,
  .areas {
    font-size: 15px;
    font-weight: 400;
  }
  .sections {
    font-size: 14px;
  }
  .btn {
    font-size: 16px;
    border-radius: 10px;
  }
}
