.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }

  .txt {
    margin-bottom: 12px;
  }

  .skipBtn {
    padding: 4px 1.8rem;
    font-size: 14px;
    border-radius: 8px;
    color: var(--secondary-blue);
  }
}
.subject {
  margin-bottom: 2rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
  margin-bottom: 2rem;

  p {
    span:first-of-type {
      margin-right: 6px;
    }

    &:nth-of-type(even) {
      margin-left: auto;
    }
  }
}

.instructions {
  margin-top: 2rem;
  font-weight: 300;

  ol {
    padding-left: 1rem;
    margin: 6px 0 1.5rem;
    line-height: 1.5;
  }
}
.areas {
  text-align: center;
  margin: 2rem 0;

  font-weight: 300;
}
.sections {
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  justify-content: center;
  margin: 2rem 0 3rem;

  span {
    border-radius: 20px;
    border: 1px solid #000;
    background: rgba(80, 200, 120, 0.2);
    padding: 6px 12px;
    margin: 0 10px 1rem;
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}

.title {
  margin-bottom: 0;
}

.instruction {
  textarea {
    line-height: 1.5;
    padding-bottom: 20px;
  }
}
.wordLimit {
  position: absolute;
  font-size: 12px;
  background: #fbfbff;
  top: 179px;
  padding-bottom: 5px;
  padding-right: 5px;
  right: 7px;
}
.name {
  margin-bottom: 12px;
}
.midSec {
  margin-bottom: 1.5rem;
  > p {
    margin-bottom: 12px;
  }

  div {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;

  span {
    padding: 1rem;
    text-align: center;
    border: 1px solid var(--secondary-blue);

    &.active {
      background: var(--secondary-blue);
      color: #fff;
      font-weight: 500;
    }
  }
}

.preview {
  line-height: 1.4;

  &__subject {
    margin-bottom: 2rem;

    b {
      margin-right: 12px;
    }
  }
  &__company {
    margin-bottom: 1rem;
    display: flex;
    gap: 12px;
    align-items: center;

    &__logo {
      height: 40px;
    }

    p {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .name {
    margin-bottom: 1.2rem;
  }

  &__body {
    margin-bottom: 1.2rem;
    white-space: break-spaces;
  }

  .midSec {
    div span {
      color: var(--secondary-blue);
    }
  }
}
