.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 6%;
  padding: 2rem;
  border-radius: 8px;
  background: #fff;
}
.btnSec {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 1rem;

    &:first-of-type {
      color: red;
      border-color: red;
    }
  }
}
.employees {
  label {
    font-size: 15px;
    background: transparent;
    padding: 0;
    color: var(--primary-blue);
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
  }

  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    button {
      border-radius: 6px;
      border: 1px solid rgba(250, 250, 251, 0.5);
      background: #f9f9f9;
      box-shadow: 0px 1.1061320304870605px 3.3183960914611816px 0px
        rgba(0, 0, 0, 0.04);
      color: #000;
      margin-right: 1rem;
      padding: 12px 10px;
      font-size: 11px;
      word-break: break-all;

      span {
        font-weight: 500;
        display: block;
        font-size: 13px;
        margin-bottom: 4px;
      }

      &.selected {
        background: var(--secondary-blue);
        color: #fff;
        border: 1px solid rgba(250, 250, 251, 0.5);
        box-shadow: 0px 1.1061320304870605px 3.3183960914611816px 0px
          rgba(0, 0, 0, 0.04);
      }
    }
  }
}
.profileHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;

  button {
    font-size: 12px;
    padding: 10px 16px;
    border-color: var(--primary-blue);
  }
}
.avatarWrap {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 8px;
//   padding: 2px;
  border: 1px solid rgba(1, 33, 105, 0.5);
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-blue);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }

  svg {
    position: absolute;
    right: -6px;
    top: -6px;
    width: 20px;
  }
}
@media screen and (max-width: 600px) {
  .container {
    padding: 1.5rem;
    gap: 3rem;
    padding: 0;
    background: transparent;

    > section {
      background: #fff;
      padding: 1.5rem 1rem;
      border-radius: 8px;
    }
  }
  .btnSec {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    button {
      margin: 0;
    }
  }
}
