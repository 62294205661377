.modal {
  padding: 1.5rem 1rem 2rem;
}
.ttl {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}
.txt1 {
  line-height: 1.5;
  color: #475467;
  margin-bottom: 2rem;
  font-size: 14px;
}
.txt3 {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 1rem;

  span {
    font-size: 14px;
    font-weight: 400;
    color: #475467;
  }
}
.inputWrap {
  margin-bottom: 1.5rem;
  > div {
    margin-bottom: 10px;
  }

  > p {
    color: #344054;
  }
}
.btnSec {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 12px;
    border-radius: 6px;
    padding: 8px 16px;
    border: none;
  }

  &__close {
    border-radius: 10px;
    background: var(--gray-200, #eaecf0);
    color: #475467;
  }

  &__upgrade {
    border-radius: 10px;
    background: var(--secondary-blue);
    box-shadow: 0px 0px 2px 0px #a29eff;
    color: #fff;
  }
}
