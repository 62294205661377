.ttl {
  color: #101323;
  font-family: "Darker Grotesque";
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 10px;
}
.txt {
  margin-bottom: 2rem;
  font-weight: 400;
  color: #667085;
  font-size: 15px;
  text-align: left;
}
.form {
}
.checkContainer {
  // margin-top: 3rem;
}
.checkWrap {
  display: flex;
  align-items: flex-start;
}
.checkWrap input {
  width: 16px;
  height: 16px;
  min-width: 16px;
  margin-top: 2px;
}
.checkWrap span {
  color: #667085;
  font-weight: 400;
  margin-left: 10px;
  font-size: 15px;
}
.checkWrap span a {
  color: #101323;
  text-transform: capitalize;
}
.btn {
  margin-top: 1.5rem;
  width: 100%;
  border-radius: 4px;
  font-weight: 400;
}
.errorMsg {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.signin {
  color: #667085;
  font-size: 15px;
  margin-bottom: 2rem;
}
.signin a {
  color: #605bff;
}
