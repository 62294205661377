.modal {
  padding: 2rem;
  text-align: center;
  position: relative;
}
.close {
  position: absolute;
  width: 24px;
  right: 2rem;
  top: 2rem;
}
.ttl {
  color: var(--primary-blue);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.txt {
  color: var(--primary-blue);
  text-align: center;
  font-size: 15px;
  margin-bottom: 1.5rem;
  font-weight: 300;
}
.btn {
  width: 100%;
  padding: 8px;
}
.inputWrap {
  height: 120px;
  display: block;
  border: 1px dashed var(--primary-blue);
  margin-bottom: 1rem;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;

  input {
    display: none;
  }

  p:first-of-type {
    color: var(--secondary-blue);
  }

  p:last-of-type {
    color: grey;
    font-size: 13px;
  }
}

.uploadedImage {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 12px;
  box-shadow: 0px 1px 4px 0px rgba(0 0 0 /0.12);
  border-radius: 6px;
  background: #fafafb;

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-right: 12px;
    border: 1px solid;
    border-radius: 4px;
    border-color: lightgrey;
    background: #fff;
  }

  svg {
    margin-left: auto;
    width: 14px;
  }
}

.description {
  height: 100px;
}
.error {
  color: #e74444;
  font-size: 12px;
  text-align: left;
}
