.empty {
  text-align: center;
  background: var(--bg-main);
  border: 1px solid var(--bg-image);
  box-shadow: var(--shadow);
  border-radius: 16px;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__img {
    max-width: 340px;
    margin-bottom: 3rem;
    width: 70%;
  }

  &__ttl {
    color: var(--text-main);
    font-weight: 600;
    font-size: var(--heading-3);
    max-width: 500px;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }

  &__txt {
    font-weight: 400;
  }

  button {
    margin-top: 3rem;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
  }
}
