.pagination {
  border-top: var(--border-1);
}

.status {
  text-transform: capitalize;
  border-radius: 24px;
  width: fit-content;
  font-weight: 500;
  padding: 6px 10px;
  text-align: center;
  min-width: 70px;
  display: block;
  margin: auto;

  &.active {
    color: #149b32;
    background: #3a974c10;
  }

  &.scheduled,
  &.paused {
    color: #f29339;
    background: #f2933910;
  }

  &.closed {
    color: #d11a2a;
    background: #d11a2a10;
  }
}

.deadline {
  &.active {
    color: #149b32;
  }

  &.scheduled {
    color: #f29339;
  }

  &.closed {
    color: #d11a2a;
  }
}

.tableBodyRow {
  .label {
    display: none;
  }
}

.title {
  cursor: pointer;
}
.dateBreakdown {
  span:last-of-type {
    margin-left: 10px;
  }

  &::after {
    content: " (GMT)";
  }
}
.mobileMenu {
  display: none;
}
@media screen and (max-width: 900px) {
  .tableBodyRow {
    margin-bottom: 1rem;
    z-index: unset !important;
    box-shadow: 1px 4px 6px 0px rgba(3, 2, 41, 0.07);
    align-items: flex-start;

    .label {
      display: block;
      font-weight: 400;
      color: #00000060;
      margin-bottom: 4px;
      font-size: 14px;
    }

    span {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .status {
    margin-left: 0;
    grid-column-start: 1;
    font-size: 13px;
    padding: 4px 10px;
    min-width: unset;
  }
  .copyLinkSec {
    position: absolute;
    right: 1rem;
    bottom: 1.5rem;
  }
  div.tableBodyRow {
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
    position: relative;
  }
  .date {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .statusWrap {
    grid-column-start: 3;
    grid-row-start: 1;
    margin-left: auto;
  }
  .deadlineWrap {
    position: unset;
    grid-column-start: 3;
    grid-row-start: 2;
    text-align: right;
  }
  .title {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .rightAligned {
    text-align: right;
  }
  .menuSec {
    grid-column-end: 4;
    grid-column-start: 1;
    justify-content: flex-start;

    > div:first-of-type {
      display: none;
    }
  }
  .title {
    grid-column-end: 3;
    grid-column-start: 1;
  }
  .mobileMenu {
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
      border-radius: 10px;
      background: rgba(39, 133, 229, 0.1);
      color: #101323;
      display: flex;
      align-items: center;
      border: none;
      padding: 10px 1.5rem;
      white-space: nowrap;
      font-weight: 500;
      gap: 10px;
      width: 100%;
      justify-content: center;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      svg {
        width: 18px;

        path {
          fill: #101323;
        }
      }
    }

    a {
      width: 18px;
      height: 18px;
    }

    svg {
      width: 18px;
    }
  }
}

@media screen and (max-width: 600px) {
  div.tableBodyRow {
    padding: 1rem;
  }
  .label {
    white-space: nowrap;
  }
}
