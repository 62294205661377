.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;
}
.label {
  font-size: 14px;
  background: transparent;
  padding: 0;
  color: #101323;
  font-weight: 500;
  margin-bottom: 8px;
}
.message {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.input {
  height: 44px;
  padding-left: 1rem;
  background: transparent;
  font-size: 14px;
  padding-right: 10px;
  color: var(--text-secondary);
  width: 100%;
  border-radius: 6px;
  border: 1px solid #dae0e6;
  box-shadow: 0px 1px 2px 0px #1018280a;

  &:disabled {
    cursor: not-allowed;
  }
}
.input::placeholder,
.textarea::placeholder {
  color: #6b6b75;
}
.textarea {
  padding-left: 1rem;
  padding-top: 12px;
  border-radius: 6px;
  border-color: #dae0e6;
  box-shadow: 0px 1px 2px 0px #1018280a;
  background: transparent;
  font-size: 14px;
  resize: none;
  height: 200px;
  outline: none;
}
.icon {
  position: absolute;
  right: 10px;
  top: 40px;
  width: 20px;
  height: auto;
  cursor: pointer;
}
.error label {
  color: #e74444;
}
.error .input,
.error .textarea {
  border-color: #e74444;
}
.req {
  color: #e74444;
  font-weight: 600;
}

.hasValue {
  background: #fbfbff;
}
