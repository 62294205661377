.container {
  display: grid;
  grid-template-columns: 500px 1fr;
  background: #fff;
  min-height: 100vh;
}
.imgSec {
  background: #fafafb;
  text-align: center;
  padding: 3rem 4rem;
  display: flex;
  flex-direction: column;
}
.img {
  width: 100%;
  max-width: 600px;
  margin: auto;
}
.logo {
  display: block;
  margin-bottom: 2rem;
  width: 160px;
}
.body {
  padding: 4rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
  }
  .img {
    display: none;
  }
  .imgSec {
    // grid-row-start: 1;
    // background: transparent;
    // padding: 1.5rem 1rem 0;
    display: none;
  }
  .logo {
    // margin: auto;
  }
  .body {
    justify-content: flex-start;
    max-width: 500px;
    margin: auto;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .logo {
    width: 140px;
    margin-bottom: 1.5rem;
  }
  .body {
    padding: 2rem 1rem;
  }
}
