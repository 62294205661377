.btn {
  padding: 12px 22px;
  border: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: 0.3s;
  border: 1px solid;
  border-radius: 6px;
  justify-content: center;
  letter-spacing: 0.3px;
  font-size: 15px;
}
.fill {
  color: #fff;
  background: var(--secondary-blue);
  border-color: var(--secondary-blue);

  &:hover {
    background: var(--primary-blue);
    border-color: var(--primary-blue);
  }
}
.transparent {
  background: transparent;
  border-color: transparent;
  color: var(--secondary-blue);
}
.outline {
  border: 1px solid var(--secondary-blue);
  color: var(--text-main);
  background: #fff;
}
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
