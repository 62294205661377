.txt {
  margin-bottom: 2rem;
}
.form {
}
.nextBtn {
  margin: auto;
  width: 220px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5rem;
  border-radius: 10px;
  height: 50px;
}