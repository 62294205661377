.txt1 {
  color: var(--primary-blue);
  margin-bottom: 2rem;
  font-size: 16px;
}
.txt2 {
  margin-bottom: 2rem;
}
.container {
  border-radius: 10px;
  background: #fff;
  padding: 5rem 2rem;
  grid-template-columns: 200px 1fr;
  display: grid;
  gap: 1.5rem;
}

.tabs {
  position: relative;
  display: grid;
  gap: 3rem;
  max-height: 660px;
  text-transform: capitalize;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 300;

    &.isSkipped {
      &::after {
        border-color: #dadada;
        background-color: #dadada;
      }
    }

    &.isCompleted {
      &::after {
        border-color: #149b32;
        background-image: url(../../../../assets/icons/tick.svg);
        background-color: #fff;
        background-size: cover;
      }
    }

    &.active {
      font-weight: 600;

      &::after {
        background: #605bff;
        border-color: #605bff;
      }
    }

    &::after {
      content: "";
      width: 32px;
      height: 32px;
      display: block;
      border-radius: 50%;
      border: 1px solid #605bff;
      position: relative;
      z-index: 1;
      background: #fff;
    }
  }

  &::after {
    content: "";
    width: 2px;
    height: calc(100% + 3rem);
    display: block;
    background: var(--primary-blue);
    opacity: 0.41;
    position: absolute;
    top: -1.5rem;
    right: 16px;
    z-index: 0;
  }

  &::before {
    content: "";
    width: 22px;
    height: calc(100% + 3rem);
    display: block;
    border-color: var(--primary-blue);
    opacity: 0.41;
    border-top: 3px solid;
    border-bottom: 3px solid;
    position: absolute;
    top: -1.5rem;
    right: 8px;
    z-index: 0;
  }
}

@media screen and (max-width: 1100px) {
  .container {
    grid-template-columns: 200px 1fr;
  }
  .tabs {
    div {
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
    padding: 3rem 1rem;
    gap: 4rem;
    margin-left: -1.5rem;
    width: calc(100% + 3rem);
  }

  .tabs {
    display: flex;
    flex-wrap: wrap;
    gap: unset;
    div {
      width: 20%;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 1rem;
      text-align: center;
      font-size: 12px;

      span {
        width: 60px;
        margin-bottom: auto;
      }

      &::after {
        margin-bottom: 10px;
      }
    }

    &::after {
      height: 60%;
      top: calc(50% - 1rem);
      transform: translateY(calc(-50% - 1rem));
      width: 100%;
      border-left: 4px solid var(--primary-blue);
      border-right: 4px solid var(--primary-blue);
      background: transparent;
      right: 0;
    }

    &::before {
      height: 52%;
      top: calc(52% - 1rem);
      transform: translateY(calc(-52% - 1rem));
      width: 100%;
      right: 0;
      border-width: 2px;
      border-color: var(--primary-blue);
    }
  }
}
