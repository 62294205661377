.main {
  background: #fff;
  padding-top: 6.2rem;
}
.wrapper {
}
.header {
  margin-bottom: 3rem;
  padding-top: 4rem;
  max-width: 500px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding-top: 3rem;

    &__ttl {
      font-size: 44px;
      margin-bottom: 1rem;
    }

    &__txt {
      font-size: 15px;
    }
  }
}
