.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;

  &.disabled {
    cursor: not-allowed;
  }
}
.label {
  font-size: 14px;
  background: transparent;
  padding: 0;
  color: #101323;
  font-weight: 500;
  margin-bottom: 8px;
}
.message {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.icon {
  position: absolute;
  right: 10px;
  top: 2rem;
  width: 20px;
  height: auto;
  cursor: pointer;
}
.select [class*="formSelect__indicator-separator"] {
  display: none;
}
.select [class*="formSelect__control"] {
  min-height: unset;
  border-radius: 6px;
  border: 1px solid #dae0e6;
  box-shadow: 0px 1px 2px 0px #1018280a;
  background: transparent;
  font-size: 14px;
  color: #000;
  height: 44px;
}
.select [class*="formSelect__control--is-focused"] {
  /* background: transparent; */
  border-color: inherit !important;
  box-shadow: none;
}
.select [class*="formSelect__input-container"] {
  height: 44px;
  margin: 0;
}
.select [class*="formSelect__value-container"] {
  padding: 0 10px 0 1rem;
}
.select [class*="formSelect__placeholder"] {
  color: #6b6b75;
}
.error > label {
  color: #e74444;
}
.error .select [class*="formSelect__control"] {
  // background: #fffafa;
  border: 1px solid #e74444;
}
.select [class*="formSelect__menu"] {
  text-transform: capitalize;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 /10%);
  background: #fff;
  border-radius: 4px;

  > div > div {
    padding-top: 12px;
    padding-bottom: 12px;

    &:hover {
      background: var(--secondary-blue);
      color: #fff;
    }
  }
}
.select svg path {
  fill: #101323;
}
.select [class*="formSelect__dropdown-indicator"] {
  padding-left: 0;
}
.req {
  color: #e74444;
  font-weight: 600;
}

.multiSelect {
  height: 44px;
  padding-left: 1rem;
  border: 1px solid rgba(1, 33, 105, 0.5);
  border-radius: 8px;
  background: transparent;
  font-size: 14px;
  padding-right: 8px;
  color: var(--primary-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;

  > p {
    color: #6b6b75;
  }

  svg {
    margin-left: 12px;
    margin-right: 6px;
    transform: rotate(-90deg);
    width: 8px;
    height: auto;

    path {
      stroke: var(--primary-blue);
    }
  }
}
.multiSelectWrapper {
  position: relative;
}
.optionsList {
  position: absolute;
  background: #fff;
  top: 113%;
  width: 100%;
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: 0px 1px 4px 1px rgb(0 0 0 /10%);
  max-height: 220px;
  overflow: auto;
  z-index: 3;

  &__noOptions {
    text-align: center;
    padding: 8px;
    text-transform: capitalize;
    color: #808080;
  }

  label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;

    span {
      font-weight: 400;
      text-transform: capitalize;
      margin-left: 10px;
    }

    &:hover {
      background: var(--secondary-blue);
      color: #fff;
    }
  }
}

span.checkInput {
  width: 18px;
  height: 18px;
}
.checkParent {
  &.disable {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
