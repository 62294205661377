.header {
  background: #fafafb;
  // top: 0;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 0 0 1rem;
  margin-bottom: 1rem;

  > div {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}
.ttl {
  font-size: 22px;
  color: var(--primary-blue);
  font-weight: 600;
}
.search {
  background: #fff;
  height: 36px;
  width: 260px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin-right: 1.5rem;

  input {
    border: none;
    background: transparent;
    width: calc(100% - 24px);
    height: 100%;
  }

  svg {
  }
}
.notification {
  position: relative;
  width: fit-content;

  svg {
    opacity: 0.5;
  }

  p {
    position: absolute;
    top: -6px;
    right: -6px;
    background: #50c878;
    width: 14px;
    height: 14px;
    font-size: 10px;
    border-radius: 50%;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    font-weight: 600;
    color: #fff;
  }
}
.info {
  color: #f29339;
  background: #f2933918;
  border: 1px solid #f29339;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 1rem;

  a {
    color: #000;
    font-weight: 500;
  }

  &--red {
    border-color: #f97066;
    color: #d92d20;
    background: #fee4e2;
  }
}
@media screen and (max-width: 900px) {
  .header {
    width: 100%;
    margin-left: 0;
    margin-top: 68px;
    align-items: center;
  }
  .info {
    margin-top: 58px;
    margin-bottom: -48px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 6px 0 1rem;
    margin-top: 58px;

    > div {
      margin-left: 0;
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 1rem;
    }
  }
  .noSearch {
    flex-direction: row;

    h1 {
      width: 100%;
    }
  }

  .ttl {
    // margin-top: 10px;
    font-size: 18px;
  }
  .info {
    flex-direction: column;
  }
}
