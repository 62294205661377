@import url(./darkerGrotesque/stylesheet.css);

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 400;
  src: local("Gelion Regular"), url("Gelion Regular.woff") format("woff");
}

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 300;
  src: local("Gelion Light"), url("Gelion Light.woff") format("woff");
}

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 200;
  src: local("Gelion Thin"), url("Gelion Thin.woff") format("woff");
}

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 500;
  src: local("Gelion Medium"), url("Gelion Medium.woff") format("woff");
}

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 600;
  src: local("Gelion Semi Bold"), url("Gelion SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 700;
  src: local("Gelion Bold"), url("Gelion Bold.woff") format("woff");
}

@font-face {
  font-family: "Gelion";
  font-style: normal;
  font-weight: 800;
  src: local("Gelion Black"), url("Gelion Black.woff") format("woff");
}
