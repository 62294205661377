.sidebar {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 220px;
  height: 100vh;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  z-index: 20;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
.logo {
  margin-left: 1.8rem;
  width: 154px;
  margin-bottom: 2rem;
}
.nav {
  margin-bottom: 2rem;
  a {
    opacity: 0.5;
    background: transparent;
    color: var(--primary-blue);
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: 15px;
    padding-left: 1.8rem;
    padding-top: 10px;
    padding-bottom: 12px;
    margin-bottom: 6px;
    white-space: nowrap;

    svg {
      margin-right: 12px;
      min-width: 20px;
      width: 20px;
      height: 20px;
    }

    &.active {
      opacity: 1;
      background: linear-gradient(90deg, #012169 -50%, rgba(1, 33, 105, 0) 20%);
    }
  }
}
.user {
  margin-top: auto;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  display: flex;
  align-items: center;

  img {
    background: #ffc7d4;
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    margin-right: 10px;
    object-fit: contain;
  }

  button {
    text-align: left;
    border: none;
    background: transparent;
    margin-right: auto;
    font-weight: 500;

    span {
      opacity: 0.5;
      display: block;
      font-size: 12px;
      font-weight: 400;
    }
  }

  svg {
  }
}
.logoSec {
  svg {
    display: none;
  }
}
.plan {
  border-radius: 12px;
  background: var(--Gray-50, #f9fafb);
  padding: 12px;
  margin: auto 1rem 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    &__ttl {
      color: #012169;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }

    &__status {
      padding: 2px 8px;
      border-radius: 6px;
      font-size: 12px;
      border: 1px solid;
      text-transform: capitalize;

      &--active {
        border-color: #32d583;
        color: #039855;
        background: #d1fadf;
      }

      &--ended,
      &--expired,
      &--terminated {
        border-color: #f97066;
        color: #d92d20;
        background: #fee4e2;
      }
    }
  }

  &__info {
    margin-bottom: 12px;
    > p {
      color: #101828;
      margin-bottom: 4px;
      font-weight: 600;
      font-size: 13px;
    }
    &__scale {
      display: grid;
      grid-template-columns: 1fr 55px;
      align-items: center;
      gap: 8px;
      max-width: 500px;
      font-size: 12px;
      color: #667085;

      b {
        color: #101828;
      }

      > div {
        width: 100%;
        height: 8px;
        background: #eaecf0;
        border-radius: 6px;
        position: relative;

        > div {
          background: var(--secondary-blue);
          height: 8px;
          border-radius: 6px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  &__txt {
    color: #667085;
    margin-bottom: 12px;
    font-size: 13px;
  }

  &__btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    button {
      color: #667085;
      background: transparent;
      border: none;
      font-size: 12px;
      text-align: center;
      margin-top: 8px;

      &:last-of-type {
        color: var(--secondary-blue);
        font-weight: 500;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}
.disabled {
  opacity: 0.2 !important;
  cursor: not-allowed;
}

@media screen and (max-width: 900px) {
  .sidebar {
    z-index: 20;
    width: 300px;
    padding: 1rem 0;
    width: 100%;
    height: unset;
    box-shadow: 1px 4px 6px 0px rgba(3, 2, 41, 0.07);

    * {
      transition: 0.5s;
    }

    .nav,
    .user {
      display: none;
    }
  }
  .logo {
    width: 120px;
    margin: 0;
  }

  .openMenu {
    height: 100vh;
    width: 100%;
    max-width: 500px;
    right: 0;
    left: unset;

    .logoSec {
      padding: 0 1.8rem;
    }

    .nav {
      display: block;
      margin-top: 2rem;
      margin-bottom: 2rem;

      a {
        width: fit-content;
      }
    }

    .user {
      display: flex;
    }
  }
  .logoSec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    svg {
      display: block;
    }

    .close {
      width: 36px;
      margin-right: -1rem;

      path {
        fill: var(--primary-blue);
        fill-opacity: 1;
        opacity: 1;
      }
    }
  }
}
