.modal {
  padding: 2rem;
  text-align: center;
  max-width: 500px;

  svg {
    width: 150px;
    margin-bottom: 1rem;
  }
}
.ttl {
  color: var(--primary-blue);
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.txt {
  color: var(--primary-blue);
  text-align: center;
  font-size: 15px;
  margin-bottom: 1.5rem;
  font-weight: 300;

  &:last-of-type {
    margin-top: 2rem;
    margin-bottom: 10px;
  }
}
.btn {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #fff;
  background: var(--secondary-blue);
  border-color: var(--secondary-blue);
  display: block;
  padding: 12px;
  border-radius: 6px;
}
.copy {
  display: flex;
  align-items: stretch;

  p {
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
    border: 0.5px solid rgba(161, 161, 176, 0.5);
    background: #fff;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.03);
    border-top-left-radius: 8px;
    border-bottom-left-radius: 6px;
    padding: 12px 10px 12px 1rem;
    color: #747474;
    width: 100%;
    text-align: left;
  }

  button {
    border-radius: 0px 8px 8px 0px;
    background: #1bb978;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.03);
    border: none;
    color: #fff;
    min-width: 90px;
  }
}
