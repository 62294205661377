.question {
  font-weight: 600;
  margin-bottom: 2rem;
  display: block;
}
.droppable {
  display: flex;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 3.5rem;
  gap: 12px;

  > div {
    border-radius: 6px;
    position: relative;
    display: block;
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -2.5rem;
      font-size: 24px;
      font-weight: 600;
    }

    &:first-of-type {
      border: 1px solid #d0cfff;
      background: rgba(208, 207, 255, 0.29);

      &::after {
        content: "1";
      }
    }

    &:nth-of-type(2) {
      border: 1px solid #f6cdcd;
      background: #f6cdcd;

      &::after {
        content: "2";
      }
    }

    &:nth-of-type(3) {
      border: 1px solid #bab9ff;
      background: rgba(186, 185, 255, 0.5);

      &::after {
        content: "3";
      }
    }

    &:nth-of-type(4) {
      border: 1px solid #dcffba;
      background: rgba(220, 255, 186, 0.72);

      &::after {
        content: "4";
      }
    }

    &:nth-of-type(5) {
      border: 1px solid #cdf6ff;
      background: rgba(205, 246, 255, 0.9);

      &::after {
        content: "5";
      }
    }

    &:nth-of-type(6) {
      border: 1px solid #f1d2ff;
      background: rgba(241, 210, 255, 0.5);

      &::after {
        content: "6";
      }
    }

    &:nth-of-type(7) {
      border: 1px solid #d5ddd9;
      background: rgba(213, 221, 217, 0.85);

      &::after {
        content: "7";
      }
    }
  }
}
.item {
  text-align: center;
}
.instruction {
  font-weight: 300;
  font-size: 13px;
  font-style: italic;
}
.container {
  margin-bottom: 3rem;
}
.errorMessage {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
@media screen and (max-width: 800px) {
  .droppable {
    grid-template-columns: 1fr;
    max-width: 120px;
    margin: auto;
    margin-bottom: 2rem;

    > div {
      margin-left: -2rem;

      &::after {
        top: 50%;
        transform: translateY(-50%);
        bottom: unset;
        left: unset;
        right: -2.5rem;
      }
    }
  }
}
