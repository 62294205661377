.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }

  .txt {
    margin-bottom: 12px;
  }

  .skipBtn {
    padding: 4px 1.8rem;
    font-size: 14px;
    border-radius: 8px;
    color: var(--secondary-blue);
  }
}

.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
}

.personalitySet {
  p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 1rem;
  }

  .personality {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(240, 240, 240, 0.6);
    background: #fff;
    margin-bottom: 2rem;
    position: relative;
    margin-left: 1.5rem;
    width: calc(100% - 1.5rem);

    &::before {
      content: "1.";
      position: absolute;
      left: -1.5rem;
      font-weight: 600;
      font-size: 20px;
    }

    &:first-of-type::before {
      content: "1.";
    }

    &:nth-of-type(2)::before {
      content: "2.";
    }

    &:nth-of-type(3)::before {
      content: "3.";
    }

    &:nth-of-type(4)::before {
      content: "4.";
    }

    label {
      padding: 14px 12px;
      border-bottom: 1px solid rgba(240, 240, 240, 0.6);
      display: flex;

      input {
        margin-right: 10px;
        width: 16px;
        height: 16px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}
.red {
  border-color: #e74444 !important; 
}
