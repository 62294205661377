.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 1.5rem;
}
.fullWidth {
  grid-column-start: 1;
  grid-column-end: 3;
}
.checkWrap {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 1.5rem;

  input {
    border: 1px solid #dae0e6;
    width: 18px;
    height: 18px;
    accent-color: var(--secondary-blue);
  }

  span {
    color: #101323;
  }
}
.btn {
  background: var(--secondary-blue);
  color: #fff;
  padding: 1rem 3rem;
  border: none;
  border-radius: 4px;
  padding: 14px 5rem;
  font-size: 15px;
}
.error {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: -1.2rem;
  margin-bottom: 2rem;
}
// .inputWrap {
//   label {
//     color: #101323;
//     font-weight: 500;
//   }
//   input,
//   textarea {
//     border-color: #dae0e6;
//     box-shadow: 0px 1px 2px 0px #1018280a;
//   }
// }

@media screen and (max-width: 800px) {
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    justify-content: space-between;

    .halfInput {
      width: 47%;
    }
  }
}
