.txt {
  color: var(--primary-blue);
  margin-bottom: 2rem;
}
.container {
  background: #fff;
  border-radius: 10px;
}
.navTabs {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-blue);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  overflow-y: hidden;
  overflow-x: auto;
  align-items: center;

  span {
    padding: 12px 1rem;
    text-align: center;
    min-width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background: var(--secondary-blue);
      color: #fff;
      border-bottom: 1px solid var(--secondary-blue);
      margin-bottom: -1px;
    }

    &:first-of-type {
      border-top-left-radius: 10px;
    }

    &:last-of-type {
      border-top-right-radius: 10px;
    }
  }
}
.full {
  background: #fff;
  padding: 2rem;
  padding-top: 50px;
  max-width: 1200px;
  margin: auto;

  .logoSec {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;

    .logo {
      max-width: 1200px;
      margin: auto;
      padding: 1rem 2rem 12px;

      svg {
        width: 160px;
      }
    }
  }
}
.actions {
  gap: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 400px;
  margin: 2rem auto 4rem;

  &__btn {
    height: 44px;
    background: #f0f0f0;
    border-color: #f0f0f0;
    color: #6b6b75;

    &:hover {
      color: red;
      background: transparent;
      border-color: currentColor;
    }
  }

  &__select {
    margin: 0;
  }
}

@media screen and (max-width: 900px) {
  .full {
    padding: 0;
    padding-top: 100px;

    .logo {
      padding: 1rem 1rem 10px;

      svg {
        width: 140px;
      }
    }
  }
  .actions {
    gap: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .actions {
    gap: 10px;
  }
}
