.container {
}

.statList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  margin-bottom: 2rem;
}

.stat {
  border-radius: 10px;
  background: #fff;
  padding: 1.5rem;
  display: flex;
  align-items: center;

  &__icon {
    width: 56px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 16px;

    svg {
      width: 20px;
    }

    &.active {
      background: #605bff10;
    }

    &.talent {
      background: #ffd66b30;
    }

    &.created {
      background: #00b69510;

      svg path {
        fill: #00b695;
      }
    }

    &.subscription {
      background: #5b93ff10;

      svg path {
        fill: #5b93ff;
      }
    }
  }

  &__value {
    font-size: 22px;
    font-weight: 700;
  }

  &__text {
    font-size: 13px;
    white-space: break-spaces;
    text-transform: capitalize;
  }
}

.overview {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 2rem;

  > div {
    border-radius: 10px;
    background: #fff;
    padding: 1.5rem;
  }
}
.ttl {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 12px;
}
.tutorial {
  .txt {
    margin-bottom: 1.3rem;
    a {
      color: var(--secondary-blue);
      text-decoration: underline;
    }
  }

  div {
    display: grid;
    grid-template-columns: 8px 1fr 8px;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 300px;
    border-radius: 12px;

    iframe {
      width: 100%;
      height: 100%;
    }

    svg:last-of-type {
      transform: rotate(180deg);
    }
  }
}
.activity {
  font-size: 12px;

  &__heading {
    display: grid;
    grid-template-columns: 1fr 1fr 0.6fr 0.6fr 0.6fr 0.5fr;
    gap: 6px;
    font-weight: 600;
    padding: 1rem 10px;
  }

  &__body {
    border-top: 1px solid #03022910;
    &__item {
      display: grid;
      grid-template-columns: 1fr 1fr 0.6fr 0.6fr 0.6fr 0.5fr;
      gap: 6px;
      padding: 1.3rem 10px;

      p span {
        display: none;
      }

      &:nth-of-type(even) {
        background: #fafafb50;
      }

      .active {
        color: #149b32;
        text-transform: capitalize;
      }

      .scheduled {
        text-transform: capitalize;
        color: #ff8a00;
      }

      .closed {
        text-transform: capitalize;
        color: #e74444;
      }
    }
  }

  &__empty {
    text-align: center;
    padding: 3rem;
    font-size: 14px;

    button {
      margin: 2rem auto 0;
      font-weight: 400;
      font-size: 13px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
.profile {
  display: flex;
  flex-direction: column;

  &__logo {
    position: relative;
    height: 60px;
    display: block;
    margin: auto;
    max-width: 250px;
    width: 80%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 8px;
      background: #fafbfc;
    }
  }

  &__upload {
    position: absolute;
    right: -8px;
    top: -8px;
    width: 24px;
    cursor: pointer;
  }

  &__name {
    font-size: 16px;
    padding-top: 1rem;
    text-align: center;
    align-items: center;

    label {
      text-align: left;
      font-size: 14px;
      margin-bottom: 6px;
      display: block;
    }

    > div {
      display: grid;
      grid-template-columns: 1fr 80px;
      gap: 1rem;

      input {
        border-radius: 6px;
        border: 1px solid rgba(96, 91, 255, 0.5);
        background: #fbfbff;
        font-weight: 500;
        height: 44px;
        padding-left: 1rem;
        font-size: 16px;
        width: 100%;
      }
      button {
        padding: 8px;
      }
    }
  }
}
.report {
  min-height: 300px;

  .secHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    > p {
      margin: 0;
    }

    &__select {
      [class*="formSelect__control"] {
        border-color: var(--primary-blue);
        height: 36px;
        border-radius: 6px;
      }

      [class*="formSelect__single-value"] {
        color: var(--primary-blue);
      }
      [class*="formSelect__input-container"] {
        height: 100%;
      }
      [class*="formSelect__value-container"] {
        padding-left: 8px;
      }
    }

    &__selectWrap {
      margin: 0;
      max-width: 130px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .overview {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 900px) {
  .statList {
    gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
  .stat {
    flex-direction: column;
    text-align: center;
    padding: 1rem;

    &__data {
      margin-bottom: auto;
    }

    &__icon {
      margin: 0 auto 12px;
      width: 44px;
      height: 44px;

      svg {
        width: 40%;
      }
    }

    &__text {
      margin-top: 6px;
    }
  }
  .overview > div {
    padding: 1rem;
  }
  .activity {
    &__heading {
      display: none;
    }
    &__body {
      border-top: unset;
      margin-top: 10px;

      &__item {
        grid-template-columns: 1fr 1fr;
        gap: 16px 10px;
        font-size: 14px;
        background: rgba(250, 250, 251, 0.8) !important;
        margin-top: 16px;
        border-radius: 6px;

        p {
          display: flex;
          flex-direction: column;
          font-weight: 500;

          span {
            display: block;
            font-weight: 400;
            margin-bottom: 4px;
            font-size: 12px;
            text-transform: capitalize;
            color: #80808090;
          }
        }
      }
    }
  }
  .profile {
    &__logo {
      margin: 2rem auto;
    }
  }
}
