.main {
  background: #fff;
  padding-top: 6.2rem;
}
.wrapper {
}
.header {
  margin-bottom: 2rem;
  padding-top: 4rem;
  max-width: 500px;

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    line-height: 1.5;
    font-size: 18px;
  }
}

.tabs {
  background: #eaecf0;
  padding: 3px;
  border-radius: 6px;
  width: fit-content;
  margin-bottom: 4rem;

  button {
    padding: 8px 1.5rem;
    border-radius: 4px;
    border: none;
    color: #344054;
    font-weight: 500;
    font-size: 18px;

    &.active {
      background: #fff;
      color: #101828;
    }
  }
}

.faqs {
  // display: grid;
  // grid-template-columns: 1fr 2.5fr;
  // gap: 2rem;
  padding-bottom: 2rem;
}
.categories {
  &__ttl {
    color: #667085;
    margin-bottom: 1rem;
  }
}
.categoryList {
  button {
    color: #667085;
    display: flex;
    background: transparent;
    font-size: 15px;
    padding: 8px;
    border: none;
    width: 100%;
    text-align: left;
    align-items: center;
    gap: 10px;
    padding: 12px 14px;
    border-radius: 6px;
    max-width: 260px;

    span {
      background: #eaecf0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
    }
    &.active {
      background: #f9fafb;
      color: #101323;
      font-weight: 500;
      span {
        background: var(--secondary-blue);
      }
    }
  }
}
.faqList {
  padding-top: 1.5rem;
}
.contact {
  color: #667085;
  font-size: 18px;
  text-align: center;
  margin-top: 3rem;

  a {
    color: var(--secondary-blue);
  }
}

@media screen and (max-width: 800px) {
  .header {
    padding-top: 3rem;
    &__ttl {
      font-size: 44px;
      margin-bottom: 1rem;
    }

    &__txt {
      font-size: 15px;
    }
  }
  .contact {
    font-size: 15px;
  }
  .tabs {
    margin-bottom: 3rem;

    button {
      font-size: 14px;
    }
  }
  .faqs {
   display: block;
  }
  .categoryList {
    display: flex;
    overflow: auto;
    margin-bottom: 1rem;

    button {
      font-size: 13px;
      white-space: nowrap;
    }
  }
  .faqList {
    padding-top: 0;
  }
}
