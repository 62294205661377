.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}
.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 0 1.5rem;
}
.timezone {
  grid-column-start: 1;
  grid-column-end: 3;
}

.dateTime {
  .inputWrap {
    margin: 0;
  }
  label {
    font-size: 15px;
    background: transparent;
    padding: 0;
    color: var(--primary-blue);
    margin-bottom: 10px;
    font-weight: 600;
    display: block;
  }
  fieldset {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
}

@media screen and (max-width: 1150px) {
  .form {
    grid-template-columns: 1fr;
  }
  .timezone {
    grid-column-end: 2;
  }
}
