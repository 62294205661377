.chart {
  width: 150px;
  position: relative;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    font-weight: 700;
  }
}
.score {
  &.green {
    color: #149b32;
  }

  &.orange {
    color: #f29339;
  }

  &.red {
    color: #d11a2a;
  }

  &.blue {
    color: #6361f9;
  }
}
.description {
  border-radius: 0px 8px 8px 0px;
  background: var(--Grayscale-10, #f9fafb);
  padding: 1rem;
  color: #667085;
  line-height: 1.5;
}

@media screen and (max-width: 800px) {
  .description {
    border-radius: 8px;
  }
}
