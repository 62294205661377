.heading {
  margin-bottom: 2rem;

  .ttl {
    margin-bottom: 1rem;
    font-size: 20px;
    text-align: center;
  }

  .txt {
    margin-bottom: 12px;
  }

  .skipBtn {
    padding: 4px 1.8rem;
    font-size: 14px;
    border-radius: 8px;
    color: var(--secondary-blue);
  }
}
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;

  span {
    padding: 1rem;
    text-align: center;
    border: 1px solid var(--secondary-blue);

    &.active {
      background: var(--secondary-blue);
      color: #fff;
      font-weight: 500;
    }
  }
}

.defaultForm {
  .newTheme {
    display: flex;
    max-width: 500px;
    margin-left: auto;
    margin-top: 2rem;

    > div {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    button {
      height: 44px;
      border-radius: 8px;

      svg {
        margin-right: 6px;
        width: 13px;
      }
    }
  }

  .instruction {
    margin-bottom: 1rem;
    line-height: 1.5;

    p {
      margin-bottom: 10px;
      display: block;

      span {
        font-weight: 500;
      }
    }
  }

  .info {
    color: var(--secondary-blue);
    border: 1px dashed currentColor;
    border-radius: 8px;
    padding: 10px 12px;
    font-size: 13px;
    display: grid;
    gap: 8px;
    grid-template-columns: 16px 1fr;
    margin-bottom: 2rem;
    width: fit-content;

    svg {
      width: 16px;
    }
  }

  > .inputWrap {
    max-width: 300px;
    margin-bottom: 10px;
    width: 70%;

    input {
      background: #fff;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .defaultQ {
    padding: 1.2rem;
    position: relative;
    background: #fafafb;
    border-radius: 8px;
    padding-right: 2.5rem;

    &__descrip {
      border-radius: 0;
      border-width: 0 0 1px 0;
      height: 36px;
      min-height: 32px;
      font-size: 13px;
      resize: vertical;
      max-height: 80px;

      &::-webkit-scrollbar {
        width: 0;
      }

      &::placeholder {
        // font-style: italic;
        opacity: 0.5;
      }
    }

    .removeBtn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 12px;
    }
  }

  .competence {
    border-radius: 10px;
    background: #fff;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;

    &.error {
      border: 1px solid red;
    }

    .removeBtn {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 12px;
    }

    .competence__count {
      font-weight: 600;
      font-size: 20px;
      margin-right: 1rem;
      line-height: 0.8;
    }

    .competence__ttl {
      margin-bottom: 1.5rem;
    }

    .inputWrap {
      display: none;
    }

    &__ttl {
      font-weight: 600;
      margin-bottom: 6px;
    }

    &__info {
      font-style: italic;
      font-weight: 300;
      margin-bottom: 10px;
      font-size: 13px;
    }

    .inputWrap {
    }
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}
.newCompetence {
  display: flex;
  margin-top: 2rem;
  max-width: 500px;
  align-items: center;
  margin-bottom: 1rem;

  .inputWrap {
    margin-bottom: 0;
  }

  input {
    background: #fff;
  }

  button {
    height: 44px;
    border-radius: 8px;
    margin-left: 1rem;

    svg {
      display: none;
    }
  }
}

.descrip {
  margin-bottom: 2rem;
  line-height: 1.8;
}
.customForm {
  .newTheme {
    display: flex;
    max-width: 500px;
    margin-left: auto;

    > div {
      margin-bottom: 0;
      margin-right: 1rem;
    }

    button {
      height: 44px;
      border-radius: 8px;

      svg {
        margin-right: 6px;
        width: 13px;
      }
    }
  }

  fieldset {
    border-radius: 10px;
    background: #fafafb;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;

    .ctrl {
      display: flex;
      position: absolute;
      right: 1rem;
      top: 1rem;

      svg {
        margin-left: 1rem;
      }

      .chevron {
        transform: rotate(-90deg);
        width: 12px;
        // right: 3.5rem;
        // top: 1.3rem;

        &.active {
          transform: rotate(90deg);
        }

        path {
          stroke: #00000090;
          stroke-width: 1;
        }
      }

      .close {
        // position: absolute;
        width: 28px;
        height: auto;
        // right: 1rem;
        top: 1rem;
      }
    }

    > p {
      // margin-bottom: 1rem;
      font-weight: 300;
      font-size: 13px;

      span {
        font-weight: 600;
      }
    }

    .quesHd {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 2rem;
      margin-bottom: 1.5rem;

      > .inputWrap {
        margin-bottom: 0;
        max-width: 240px;
        width: 70%;

        input {
          background: #fff;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .scoreAndTimer {
      display: flex;
      align-items: center;
      > p {
        margin-left: 14px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
        color: var(--primary-blue);
      }
    }
    .timeWrap {
      flex-direction: column-reverse;
      width: 40px;
      margin-bottom: 0;
      margin-left: 6px;
      margin-top: 15px;

      label {
        margin-bottom: 0;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
      }

      input {
        text-align: center;
        padding: 0;
        padding-bottom: 12px;
        // padding-left: 2px;
        width: fit-content;
        margin: auto;
        max-width: 100%;
        height: 36px;
      }
    }

    .scoreWrap {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      width: fit-content;

      label {
        margin-bottom: 0;
        margin-right: 8px;
        font-weight: 400;
        font-size: 13px;
        white-space: nowrap;
      }

      input {
        height: 36px;
        width: 40px;
        padding: 0;
        text-align: center;
        // padding-left: 2px;
      }
    }

    .customQuestion {
      margin-top: 1rem;
      margin-top: 1rem;
      padding: 1.5rem;
      background: #fff;
      border-radius: 10px;

      .open_ended {
        display: none;
      }

      &__unfocused {
        .description,
        .others > div,
        .selectWrap {
          display: none;
        }

        .others .points {
          display: flex;
        }

        .others .imageUpload {
          margin-right: auto;
          margin-top: 12px;
          margin-left: 2rem;

          &--hide {
            display: none;
          }
        }

        .open_ended {
          border-radius: 10px;
          background: rgba(96, 91, 255, 0.05);
          padding: 12px;
          margin-top: 12px;
          margin-left: 2rem;
          margin-right: 1rem;
          display: block;

          p:last-of-type {
            margin-left: auto;
            width: fit-content;
            margin-top: 10px;
            font-size: 12px;
          }
        }

        .questionWrap {
          border: none;
          margin-top: -2.3rem;
          width: calc(100% - 2.3rem);
          margin-left: 1rem;
          padding: 0;
          margin-bottom: 0;
          grid-template-columns: 1fr;

          .inputWrap {
            width: 100%;
          }

          textarea,
          input {
            font-weight: 500;
          }

          textarea {
            max-height: fit-content;
          }
        }

        .customCount p {
          display: block;
          font-weight: 600;
          font-size: 18px;
        }

        .options {
          margin-left: 2.2rem;

          > p {
            margin: 0px 0 10px;
          }

          .addOptions {
            display: none;
          }
        }

        .option {
          display: flex;
          flex-direction: row-reverse;
          gap: 0;
          position: relative;
          width: fit-content;
          margin-bottom: 6px;

          &::before {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            border: 1px solid var(--secondary-blue);
            position: absolute;
            left: 0;
            z-index: 1;
          }

          &__radio {
            &::before {
              border-radius: 50%;
            }
          }

          &__value {
            width: fit-content;
          }

          input {
            border: none;
            background: transparent;
            height: fit-content;
            padding-left: 2rem;
          }

          &__remove {
            display: none;
          }

          &__correct {
            width: 20px;
          }
        }
      }
    }

    .customCount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: -10px;

      p {
        display: none;
      }
    }

    .questionWrap {
      display: flex;
      border-radius: 6px;
      border: 1px solid rgba(1, 33, 105, 0.5);
      align-items: center;
      padding: 10px;
      margin-bottom: 10px;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 150px;
      gap: 1rem;

      .inputWrap {
        height: fit-content;
        margin-bottom: 0;
        // width: calc(100% - 130px);
        // margin-right: 20px;
        padding-top: 6px;

        textarea {
          border: none;
          background: #fff !important;
          resize: vertical;
          min-height: 32px;
          height: 36px;
          max-height: 80px;
          padding-top: 0;

          &::-webkit-scrollbar {
            width: 2px;
          }

          ::-webkit-scrollbar-thumb {
            background: var(--secondary-blue);
            border-radius: 5px;
          }
        }
      }
      .selectWrap {
        margin-bottom: 0;
        width: 150px;

        [class*="formSelect__control"] {
          background: var(--secondary-blue);
          color: #fff;
          height: 32px;
          border: none;
        }

        [class*="formSelect__input-container"] {
          height: 100%;
        }

        [class*="formSelect__placeholder"],
        [class*="formSelect__single-value"] {
          color: #fff;
        }

        [class*="formSelect__value-container"] {
          padding: 0;
          padding-left: 6px;
        }

        [class*="formSelect__dropdown-indicator"] {
          padding: 0;
          padding-right: 8px;

          svg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    .description {
      max-width: 400px;
      margin-bottom: 10px;

      textarea {
        border-radius: 0;
        border-width: 0 0 1px 0;
        height: 32px;
        min-height: 32px;
        font-size: 13px;
        resize: vertical;
        max-height: 50px;

        &::-webkit-scrollbar {
          width: 0;
        }

        &::placeholder {
          font-style: italic;
          opacity: 0.5;
        }
      }
    }

    .others {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      row-gap: 8px;
    }

    .imageUpload {
      display: flex;
      align-items: center;
      color: rgba(78, 78, 78, 0.5647058824);
      background: transparent;
      gap: 8px;
      margin-right: 1rem;
      border: none;

      span {
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .wordLimit {
      margin-bottom: 0;
      width: 36px;

      input {
        height: 28px;
        padding: 0;
        text-align: center;
      }
    }
    .toggleWrap {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-right: 6px;

      > span {
        margin-right: 6px;
        color: #4e4e4e90;
        white-space: nowrap;
      }

      svg {
        width: 28px;
      }
    }
    .addBtn {
      padding: 8px;
      width: 30px;
      height: 30px;
      border-radius: 8px;
      background: var(--secondary-blue);
      border-color: var(--secondary-blue);
      margin-top: 1rem;
    }
    .removeBtn {
      margin-left: auto;
      display: block;
      width: 12px;
    }
  }
  .maximumSelectionWrap {
    margin: 0;
  }
  .maximumSelection {
    [class*="formSelect__control"] {
      height: 28px;
    }

    [class*="formSelect__indicators"] {
      height: 100%;
    }

    [class*="formSelect__value-container"] {
      padding: 0;
      height: 100%;
      width: 26px;
      padding-left: 4px;
    }

    [class*="formSelect__dropdown-indicator"] {
      padding: 0;
    }

    [class*="formSelect__input-container"] {
      height: 100%;
    }
    svg {
      width: 14px !important;
    }
  }
  .options {
    > p {
      color: #a4a6b3;
      font-style: italic;
      font-weight: 300;
      margin: 1rem 0;
      font-size: 12px;
    }
    p.error {
      color: red;
      font-style: normal;
      font-size: 12px;
    }

    .addOptions {
      padding: 0;
      font-weight: 400;
      font-size: 13px;
      margin-top: 2rem;

      svg {
        width: 11px;
        margin-right: 8px;

        path {
          stroke: var(--secondary-blue);
          stroke-width: 2px;
        }
      }
    }
  }
  .option {
    max-width: 450px;
    align-items: center;
    display: grid;
    grid-template-columns: 30px 1fr 24px;
    gap: 1.5rem;
    margin-bottom: 1.5rem;

    &__value {
      margin: 0;

      input {
        border-radius: 0;
        border-color: var(--secondary-blue);
      }
    }

    &__correct {
      width: 30px;

      &--disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    &__remove {
      width: 14px;
      path {
        fill: var(--secondary-blue);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .defaultForm {
    fieldset {
      padding: 1rem;

      .chevron {
        right: 3rem;
        width: 10px;
      }

      .close {
        right: 12px;
        width: 24px;
      }
    }
    .newCompetence {
      button {
        padding: 6px;
        height: fit-content;

        span {
          display: none;
        }
        svg {
          display: block;
          padding: 2px;
        }
      }
    }
  }

  .customForm {
    fieldset {
      padding: 1rem;

      .ctrl {
        top: 10px;
        right: 0;

        .close {
          width: 24px;
          margin-left: 12px;
        }

        .chevron {
          width: 10px;
        }
      }

      .quesHd {
        > .inputWrap {
          width: 100%;
          max-width: 300px;
        }
      }

      .scoreAndTimer {
        justify-content: flex-end;
        width: 100%;
        margin-top: 16px;
      }
      .customCount {
        margin-top: 0;
      }
      .scoreWrap {
        margin-right: auto;
      }
      .customQuestion {
        padding: 1rem;

        &__unfocused {
          .questionWrap {
            padding-left: 12px;
          }
        }
      }

      .questionWrap {
        grid-template-columns: 1fr 120px;

        .inputWrap {
          // width: calc(100% - 120px);

          input {
            padding-left: 0;
          }
        }

        .selectWrap {
          width: 120px;
        }
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .customForm {
    fieldset {
      .questionWrap {
        grid-template-columns: 1fr;

        .selectWrap {
          width: 100%;
        }
      }
    }
  }
}
