.pagination {
  border-top: var(--border-1);
}

.status {
  text-transform: capitalize;
  border-radius: 24px;
  width: fit-content;
  font-weight: 500;
  padding: 6px 10px;
  text-align: center;
  min-width: 70px;
  display: block;
  margin: auto;

  &.success, &.active {
    color: #149b32;
    background: #3a974c10;
  }

  &.pending {
    color: #f29339;
    background: #f2933910;
  }

  &.failed, &.terminated {
    color: #d11a2a;
    background: #d11a2a10;
  }
}
.copyBtn {
  color: #012169;
  border-radius: 10px;
  background: rgba(39, 133, 229, 0.1);
  border: none;
  outline: none;
  padding: 10px 1.2rem;
  white-space: nowrap;
  font-weight: 500;
  margin-left: auto;
  display: block;

  svg {
    display: none;
  }
}
.tableBodyRow {
  text-transform: capitalize;
  .label {
    display: none;
  }
}
.date {
  span:last-of-type {
    margin-left: 10px;
  }

  &::after {
    content: " (GMT)";
  }
}
.metrics {
  text-transform: capitalize;
}
@media screen and (max-width: 600px) {
  .tableBodyRow {
    position: relative;
    .label {
      display: block;
      font-weight: 400;
      color: #00000060;
      margin-bottom: 4px;
      font-size: 14px;
    }

    span {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .statusWrap {
    position: absolute;right: 1rem;
    top: 1rem;
  }

  .status {
    margin: 0;
    font-size: 13px !important;
    padding: 4px 10px;
    min-width: unset;
  }

  .copyBtn {
    background: transparent;
    text-decoration: underline;
    display: flex;
    align-items: center;
    padding: 0;

    svg {
      display: block;
      margin-left: 4px;
      transform: rotate(180deg);
      border: 1px solid var(--primary-blue);
      padding: 4px;
      width: 16px;
      height: 16px;
      border-radius: 5px;

      path {
        stroke: var(--primary-blue);
      }
    }
  }
}
