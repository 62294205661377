.heading {
  margin-bottom: 1.5rem;
  p {
    color: #101323;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;

    &::after {
      content: "";
      height: 2px;
      width: 100%;
      display: block;
      background: #f2f4f7;
    }
  }
}
