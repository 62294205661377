.ttl {
  color: #101323;
  font-family: "Darker Grotesque";
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 10px;
}
.txt {
  margin-bottom: 2rem;
  font-weight: 400;
  color: #667085;
  font-size: 15px;
  text-align: left;
}
.form {
}
.btn {
  margin-top: 1.5rem;
  width: 100%;
  border-radius: 4px;
  font-weight: 400;
}
.errorMsg {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.signin {
  color: #667085;
  font-size: 15px;
  margin-bottom: 2rem;
}
.signin a {
  color: #605bff;
}
.otherInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    color: #101323;
    font-weight: 400;
    display: flex;
    align-items: center;

    input {
      margin-right: 10px;
      width: 16px;
      height: 16px;
    }
  }

  a {
    padding: 0;
    color: #605bff;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: normal;
  }
}
