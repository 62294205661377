.wrapper {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.ttl {
  font-family: "Darker Grotesque";
  font-size: 60px;
  font-weight: 700;
  max-width: 750px;
  line-height: 1.25;
  color: #101323;
  margin-bottom: 3rem;

  span {
    color: var(--secondary-blue);
  }
}
.content {
  display: grid;
  grid-template-columns: 1.2fr 2fr;
  gap: 2rem;
  align-items: center;

  &__img {
    width: 100%;
    max-width: 346px;
  }
}
.review {
  &__txt {
    color: #101323;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 1.8rem;
  }
  &__name {
    font-family: "Darker Grotesque";
    color: #101323;
    font-weight: 600;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  &__role {
    color: #667085;
    font-size: 18px;
  }
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: 44px;
  }
  .review {
    &__txt {
      font-size: 15px;
    }
    &__name {
      font-size: 18px;
    }
    &__role {
      font-size: 15px;
    }
  }
}
@media screen and (max-width: 600px) {
  .ttl {
    font-size: 36px;
  }
  .content {
    grid-template-columns: 1fr;

    &__img {
      margin: auto;
    }
  }
}
