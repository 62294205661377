.bg {
  background: #fff;
}
.wrapper {
  padding-bottom: 3rem;
}
.heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 7rem;

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 10px;
  }
  &__txt {
    color: #667085;
    font-weight: 400;
    max-width: 380px;
    margin-bottom: 2.5rem;
    font-size: 18px;
    letter-spacing: normal;
    line-height: 1.8;
  }
  &__cycle {
    color: #d0d5dd;
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 1rem;

    &__active {
      color: #101323;
      b {
        color: inherit !important;
      }
    }

    span {
      b {
        color: #afadff;
        font-weight: 400;
      }
    }
  }
}

.plans {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.3rem;
  margin-bottom: 2rem;
}
.plan {
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 1.5rem;

  &:nth-of-type(2) {
    margin-top: -40px;
    box-shadow: 0px 0px 12px 0px #605bff80;
    border-color: #605bff;
  }

  &__ttl {
    font-family: "Darker Grotesque";
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      background: var(--secondary-blue);
      color: #fff;
      font-family: "Gelion";
      font-size: 11px;
      font-weight: 300;
      padding: 4px 12px;
      border-radius: 12px;
    }
  }
  &__txt {
    color: #5f6d7e;
    font-size: 15px;
    margin-bottom: 2rem;
  }
  &__price {
    display: flex;
    align-items: flex-end;
    gap: 4px;
    margin-bottom: 10px;

    &__amount {
      font-family: "Darker Grotesque";
      color: #101323;
      font-size: 32px;
      font-weight: 800;
    }
    &__duration {
      color: #667085;
      font-size: 17px;
      margin-bottom: 4px;
    }
  }
  &__txt2 {
    color: #667085;
    font-size: 12px;
    margin-bottom: 2rem;
  }

  &__link {
    width: 100%;
    display: block;
    text-align: center;
    background: var(--secondary-blue);
    color: #fff;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 2.2rem;
    font-size: 14px;
  }
  &__txt3 {
    color: #667085;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  &__features {
    p {
      color: #667085;
      font-size: 15px;
      line-height: 2;
      display: flex;
      align-items: center;
      gap: 6px;

      svg {
        width: 22px;

        path {
          stroke: #667085;
          stroke-width: 2px;
        }
      }
    }
  }
}
.starter {
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;

  &__ttl {
    color: #101323;
    font-family: "Darker Grotesque";
    font-weight: 800;
    font-size: 22px;
    margin-bottom: 12px;
  }
  &__txt {
    color: #475467;
    font-size: 15px;
  }
  &__link {
    color: var(--secondary-blue);
    border: 1px solid var(--secondary-blue);
    text-align: center;
    padding: 12px 3rem;
    border-radius: 6px;
    font-weight: 500;
    white-space: nowrap;
  }
}
.custom {
  &__link {
    color: #fff;
    background: var(--secondary-blue);
    border: 1px solid var(--secondary-blue);
    text-align: center;
    padding: 12px 3rem;
    border-radius: 6px;
    font-weight: 500;
    white-space: nowrap;
  }
}

@media screen and (max-width: 800px) {
  .heading {
    padding-top: 3rem;
    padding-bottom: 4rem;
    &__ttl {
      font-size: 40px;
    }
    &__txt {
      font-size: 15px;
      line-height: 1.5;
      max-width: 320px;
      margin-bottom: 2rem;
    }
    &__cycle {
      font-size: 15px;
    }
  }
  .plans {
    grid-template-columns: 1fr;
  }

  .plan {
    &:nth-of-type(2) {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding-bottom: 2rem;
  }
  .plan {
    padding: 1rem;
    &__ttl {
      font-size: 20px;
    }
    &__txt {
      font-size: 14px;
      margin-bottom: 1rem;
    }
    &__price {
      margin-bottom: 6px;
      &__amount {
        font-size: 30px;
      }
      &__duration {
        font-size: 14px;
      }
    }
    &__txt2 {
      margin-bottom: 1.5rem;
    }
    &__txt3 {
      font-size: 14px;
    }
    &__features p {
      font-size: 14px;
    }
  }
  .starter {
    padding: 1rem;
    display: grid;
    gap: 2rem;

    &__ttl {
      font-size: 20px;
      margin-bottom: 8px;
    }

    &__txt {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}
