.main {
  margin-left: 220px;
  padding: 1rem 1.5rem;
  padding-bottom: 3rem;
}

@media screen and (max-width: 900px) {
  .main {
    margin-left: 0;
    padding: 1rem 1rem;
    padding-bottom: 5rem;

  }
}
