.sec1 {
  display: grid;
  grid-template-columns: 2fr 1.2fr;
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 4rem;
}
.plan {
  background: var(--white);
  background: #fff;
  border-radius: 8px;
  border-top-left-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  &__tags {
    display: flex;
    position: absolute;
    top: -34px;
  }

  &__cancelBtn {
    color: #e74444;
    background: #fff;
    padding: 8px 14px;
    border: none;
    border-top-right-radius: 8px;
    font-size: 14px;
  }

  &__tag {
    background: var(--primary-blue);
    color: #fff;
    width: fit-content;
    padding: 8px 14px;
    border-top-left-radius: 8px;
  }

  &__hd {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__ttl {
      color: #101828;
      font-weight: 600;
      font-size: 18px;
      display: flex;
      align-items: center;
      text-transform: capitalize;

      span {
        border: 1px solid #eaecf0;
        color: #475467;
        padding: 2px 6px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: normal;
        margin-left: 1rem;
      }
    }

    &__status {
      padding: 4px 8px;
      border-radius: 4px;
      font-size: 13px;
      border: 1px solid;
      text-transform: capitalize;

      &--active {
        border-color: #32d583;
        color: #039855;
        background: #d1fadf;
      }

      &--ended,
      &--expired,
      &--terminated {
        border-color: #f97066;
        color: #d92d20;
        background: #fee4e2;
      }
    }
  }

  &__info {
    padding: 0 1rem 1rem;

    &__scale {
      display: grid;
      grid-template-columns: 1fr 100px;
      align-items: center;
      gap: 1rem;
      max-width: 500px;

      > div {
        width: 100%;
        height: 8px;
        background: #eaecf0;
        border-radius: 6px;
        position: relative;

        > div {
          background: var(--secondary-blue);
          height: 8px;
          border-radius: 6px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    &__price {
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 8px;

      p {
        color: #101828;
        font-weight: 700;
        font-size: 32px;
        margin-right: 8px;
      }

      span {
        color: #475467;
      }
    }
    &__txt {
      color: #667085;
      margin-bottom: 2rem;
    }
  }

  &__card {
    gap: 12px;

    svg {
      height: 24px;
      width: auto;
    }

    p {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-right: auto;
    }

    button {
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-left: auto;
    }
  }

  &__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--gray-200, #eaecf0);

    p {
      color: #667085;
      b {
        color: #101828;
      }
    }
    button {
      border: 1px solid var(--gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 8px 24px;
      color: #344054;
      border-radius: 8px;
    }
  }
}
.monitoring {
  background: var(--white);
  background: #fff;
  border-radius: 8px;

  &__hd {
    padding: 1rem;
    border-bottom: 1px solid var(--gray-200, #eaecf0);

    &__ttl {
      color: #101828;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 6px;
    }

    &__txt {
      color: #667085;

      b {
        color: #101828;
      }
    }
  }

  &__info {
    padding: 1rem;

    &__txt1 {
      color: #101828;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 4px;
    }

    &__txt2 {
      color: #667085;
      line-height: 1.5;
    }
  }

  &__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--gray-200, #eaecf0);

    button {
      border: 1px solid var(--gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 8px;
      color: #344054;
      border-radius: 8px;
      width: 100%;
      // max-width: 170px;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.sec2 {
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 2rem;

  &__hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    p {
      color: #101828;
      font-size: 24px;
      font-weight: 700;
    }

    button {
      color: var(--secondary-blue);
      border: 1px solid currentColor;
      padding: 6px 12px;
      border-radius: 6px;
      background: transparent;
      font-weight: 500;
    }
  }
}

.pricing {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;

  &__plan {
    display: flex;
    flex-direction: column;

    &__tag {
      border-radius: 4px;
      width: fit-content;
      padding: 6px 12px;
      font-weight: 500;
      margin-bottom: 12px;

      &--pay_as_you_go {
        color: #f27b0a;
        background: #ffd4a1;
      }

      &--regular {
        color: #3c38a9;
        background: #cfcdff;
      }

      &--pro {
        color: #027a48;
        background: #d1fadf;
      }
    }

    &__txt {
      color: #475467;
      line-height: 1.5;
      margin-bottom: 1rem;
    }

    &__fee {
      display: flex;
      align-items: flex-end;
      margin-top: 10px;
      margin-bottom: 1.5rem;

      p {
        color: #101828;
        font-weight: 700;
        font-size: 44px;
        margin-right: 8px;
        line-height: 0.8;
      }

      span {
        color: #475467;
        font-size: 16px;
      }
    }

    &__benefits {
      list-style: none;
      color: #475467;
      margin-bottom: 2rem;

      li {
        display: flex;
        align-items: center;
        line-height: 1.5;
        margin-bottom: 12px;
        gap: 10px;

        // svg {
        //   margin-right: 10px;
        // }
      }
    }

    button {
      width: 100%;
      padding: 8px;
      font-weight: 400;
      margin-top: auto;
    }
  }
}

@media screen and (max-width: 900px) {
  .sec1 {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .sec1 {
    margin-bottom: 2rem;
  }

  .sec2 {
    background: transparent;
    padding: 0;

    &__hd {
      padding: 1rem;
      margin-bottom: 0;
    }
  }

  .plan {
    &__card {
      flex-wrap: wrap;

      button {
        width: 100%;
      }
    }
  }

  .pricing {
    gap: 1rem;

    &__plan {
      background: #fff;
      border-radius: 12px;
      padding: 1rem;

      &__fee {
        p {
          font-size: 36px;
        }
        span {
          font-size: 14px;
        }
      }
    }
  }
}
