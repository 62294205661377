.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  // align-items: flex-end;

  .inputWrap {
    margin: 0;
  }
}
.phoneWrap {
  position: relative;
}
.phoneTxt {
  color: #000;
  font-size: 13px;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  bottom: -22px;
}
.nextBtn {
  margin: auto;
  width: 220px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5rem;
  border-radius: 10px;
  height: 50px;
}

.fileWrap {
  .label {
    font-size: 15px;
    background: transparent;
    padding: 0;
    color: var(--primary-blue);
    margin-bottom: 10px;
    font-weight: 600;
    height: fit-content;
  }
  &.error {
    label {
      border-color: red;
    }
    .label {
      color: red;
    }
  }
  label {
    border: 1px dashed #012169;
    background: #fff;
    width: 100%;
    display: flex;
    // height: 100%;
    border-radius: 12px;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    min-height: 90px;

    p {
      margin: 2px;

      span {
        color: var(--secondary-blue);
        // font-weight: 500;
      }

      &.limit {
        font-size: 12px;
        color: grey;
      }
    }
  }
  .fileInput {
    margin: 0;
    display: none;
  }
  input {
    display: none;
  }

  .file {
    display: none;
  }

  .errorMsg {
    font-size: 12px;
    color: #e74444;
    display: block;
    margin-top: 8px;
    height: fit-content;
  }
}
.uploaded {
  label {
    border-style: solid;
    border-color: var(--secondary-blue);
    border-bottom-right-radius: 0;

    p {
      margin: auto;
      padding-top: 10px;
    }
  }

  p {
    height: 100%;
    display: flex;
    align-items: center;

    span {
      margin-left: 4px;
    }
  }
  .file {
    width: 100%;
    margin-top: auto;
    display: block;
    background: var(--secondary-blue);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0;
    padding: 6px;
    color: #fff;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 800px) {
  .form {
    gap: 1.5rem;
  }
}
