.container {
  display: grid;
  gap: 1.5rem;

  * {
    transition: 0.3s;
  }
}

.comparisonBar {
  position: relative;
  z-index: 2;
  display: flex;
  position: relative;

  * {
    transition: 0.3s;
  }

  &__bar {
    width: 0px;
    height: 8px;
    background: var(--primary-blue);
    border-radius: 20px;
    transition: width 0.3s ease-out;
    position: absolute;
    z-index: 1;
    left: 0;

    &__white {
      background: rgba(107, 107, 117, 0.12);
      border-radius: 20px;
      width: 100%;
      height: 8px;
    }
  }
}
.info {
  display: flex;
  justify-content: space-between;

  &__item {
    font-size: 15px;
    &__txt1 {
      margin-bottom: 4px;
      font-weight: 500;
    }

    &:last-of-type {
      text-align: right;
    }
    &:first-of-type {
      text-align: left;
    }
  }

  &__item:last-of-type {
    &__txt1 {
      color: #101323;
    }
    &__txt2 {
      color: #667085;
    }
  }
}

.bg-green {
  background: #18b368;
}
.bg-blue {
  background: #605bff;
}
.bg-orange {
  background: #eda12f;
}
.bg-red {
  background: #e62e2e;
}

.green {
  color: #18b368;
}
.blue {
  color: #605bff;
}
.orange {
  color: #eda12f;
}
.red {
  color: #e62e2e;
}

.comparison {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem 2.5rem;

  &__body {
    &__title {
      color: #101323;
      font-size: 18px;
      text-transform: capitalize;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__description {
      color: #667085;
      line-height: 1.5;
      margin-top: 1rem;
      font-size: 15px;
    }
  }
}

// @media screen and (max-width: 900px) {
//   .comparison {
//     padding: 0;

//     &__heading {
//       font-size: 14px;
//       margin-left: 25%;
//       margin-bottom: 1rem;
//     }
//     &__body {
//       margin-bottom: 1rem;
//       grid-template-columns: 1fr 3fr;
//       p {
//         font-size: 14px;
//         text-align: left;
//         word-break: break-word;
//       }
//     }
//   }
// }
@media screen and (max-width: 800px) {
  .comparison {
    grid-template-columns: repeat(auto-fit, minmax(300px,1fr));
    gap: 2rem;
  }
}

@media screen and (max-width: 500px) {
  .comparison {
    &__body {
      p {
        font-size: 14px;
      }
    }

    .legend {
      gap: 10px;
      font-size: 14px;
      align-items: flex-end;

      div {
        &::after {
          margin-top: 12px;
        }
        span {
          margin-bottom: 2px;
        }
      }
    }
  }

  .comparisonBar {
    &__rating {
      font-size: 12px;
    }

    &:first-of-type {
      .comparisonBar__rating {
        margin-left: 4px;
      }
    }

    &:last-of-type {
      .comparisonBar__rating {
        margin-right: 4px;
      }
    }
  }
}
