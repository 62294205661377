.txt {
  color: #012169;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  gap: 1rem;

  > button {
    font-weight: 400;
    font-size: 14px;
  }
}
.filterSec {
  display: grid;
  grid-template-columns: 150px 150px;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  z-index: 16;

  > p {
    color: var(--primary-blue);
    font-size: 16px;
    font-weight: 500;
  }

  .selectWrap {
    margin: 0;
    z-index: 15;
  }
  .select {
    [class*="formSelect__control"] {
      border-color: var(--primary-blue);
      border-radius: 8px;
    }

    [class*="formSelect__single-value"] {
      color: var(--primary-blue);
    }
  }
}
.bucketFilter {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2rem;

  .selectWrap {
    margin: 0;
    z-index: 15;
    width: 150px;

    label {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 4px;
    }
  }

  .select {
    [class*="formSelect__control"] {
      border-color: var(--primary-blue);
      height: 40px;
      // border-radius: 4px;
    }

    [class*="formSelect__single-value"] {
      color: var(--primary-blue);
    }
    [class*="formSelect__input-container"] {
      height: 100%;
    }
    [class*="formSelect__value-container"] {
      padding-left: 14px;
    }
  }

  button {
    padding: 8px 16px;
    margin-top: auto;
    font-size: 14px;
    border-radius: 6px;
    font-weight: 400;
    height: 40px;
  }
}
.container {
  background: #fff;
  padding: 0 2rem 1rem;
  border-radius: 16px;
}
.tabs {
  display: flex;
  margin-left: -2rem;
  border-top-left-radius: inherit;
  border-radius: inherit;
  margin-bottom: 2rem;

  span {
    padding: 10px 2rem;
    font-size: 18px;
    border-bottom: 1px solid var(--primary-blue);

    &.active {
      background: var(--secondary-blue);
      border-color: var(--secondary-blue);
      color: #fff;
      font-weight: 500;
    }

    &:first-of-type {
      border-top-left-radius: inherit;
    }

    &:last-of-type {
      // border-top-right-radius: inherit;
      border-bottom-right-radius: 12px;
    }
  }
}
div.tableRow,
section.tableHeader {
  display: grid;
  grid-template-columns: 16px repeat(3, 1fr) 120px 100px 100px 60px;
  gap: 1rem;
  text-align: center;

  span:first-of-type {
    text-align: left;
  }
}

div.tableRow,
section.tableHeader {
  span:first-of-type,
  span:nth-of-type(2) {
    font-weight: 600;
  }
}
.ctaSec {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 900px) {
  div.tableRow {
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
  .tableHeader {
    display: none;
  }
  .txt {
    margin-bottom: 2rem;
    margin-top: -1rem;
  }
  .container {
    border-radius: 8px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  section.tableHeader {
    padding: 0 1rem;
    grid-template-columns: 1fr;

    * {
      display: none;
    }

    span:first-of-type {
      display: block;

      * {
        display: block;
      }
    }
  }

  .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-bottom: 2rem;
    overflow: auto;

    span {
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      min-width: 80px;

      &:last-of-type {
        border-bottom-right-radius: 8px;
      }
    }
  }
  .filterSec {
    grid-template-columns: 130px 130px;

    > p {
      font-size: 14px;
    }

    .select {
      [class*="formSelect__control"] {
        height: 36px;
        border-radius: 6px;
      }
      [class*="formSelect__input-container"],
      [class*="formSelect__value-container"] {
        height: 100%;
      }
      [class*="formSelect__dropdown-indicator"] {
        padding: 0 10px 0 0;
      }

      [class*="formSelect__value-container"] {
        padding-left: 10px;
      }
    }
  }
  .ctaSec {
    button {
      padding: 8px 1rem;
      font-size: 14px;
      font-weight: 400;
      height: 36px;
    }
  }
  .bucketFilter {
    grid-template-columns: 40px 130px;
    > p {
      font-size: 14px;
    }
    .select {
      [class*="formSelect__control"] {
        height: 36px;
        border-radius: 6px;
      }
      [class*="formSelect__input-container"],
      [class*="formSelect__value-container"] {
        height: 100%;
      }
      [class*="formSelect__dropdown-indicator"] {
        padding: 0 10px 0 0;
      }

      [class*="formSelect__value-container"] {
        padding-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .actions .ctaSec {
    flex-direction: row-reverse;
    button {
      padding: 8px 1rem;
    }
    .info {
      div {
        left: -120px;

        &::before {
          top: -6px;
          left: 124px;
          right: 0;
        }
      }
    }
  }
  .ctaSec {
    button {
      padding: 8px;
    }
    .info {
      div {
        left: -8px;

        &::before {
          top: -6px;
          left: 12px;
          right: 0;
        }
      }
    }
  }
}
