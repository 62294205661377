.heroBg {
  position: relative;
  background: #fafafb;
  padding-top: 6.2rem;

  &__img {
    position: absolute;
    right: 0;
  }
}
.hero {

  &__content {
    padding: 4rem 0 2rem;
  }

  &__ttl {
    font-family: "Darker Grotesque";
    font-size: 60px;
    font-weight: 700;
    max-width: 600px;
    line-height: 1.25;
    color: #101323;
    margin-bottom: 1.5rem;

    span {
      color: var(--secondary-blue);
    }
  }

  &__txt {
    color: #667085;
    font-weight: 400;
    max-width: 600px;
    margin-bottom: 2rem;
    font-size: 18px;
    letter-spacing: normal;
    line-height: 1.8;
  }

  &__btn {
    background: var(--secondary-blue);
    color: #fff;
    border: none;
    padding: 12px 1.3rem;
    font-size: 15px;
    border-radius: 6px;
    margin-bottom: 1.5rem;
  }

  &__contact {
    color: #667085;
    font-size: 17px;
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      padding: 0;
      background: none;
      font-size: inherit;
      color: #101323;
      border: none;
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;

      svg {
        path {
          stroke: #101323;
        }
      }
    }
  }

  &__img {
    width: 105%;
    margin-left: -2.5%;display: block;
  }
}

@media screen and (max-width: 800px) {
  .heroBg {
    &__img {
      display: none;
    }
  }
  .hero {
    &__content {
      padding-top: 1rem;
    }
    &__ttl {
      font-size: 40px;
      max-width: 400px;
    }
    &__txt {
      font-size: 15px;
      line-height: 1.5;
      max-width: 500px;
    }
    &__btn {
      margin-bottom: 1rem;
    }
    &__contact {
      font-size: 15px;
    }
  }
}
