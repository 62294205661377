.modal {
  padding: 1.5rem 2rem 2rem;
  max-width: 480px;
}
.ttl {
  font-size: 24px;
  margin-bottom: 8px;
}

.txt {
  margin-bottom: 1rem;
  font-size: 15px;
  line-height: 1.4;
}

.btn {
  width: 100%;
}
