.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 3rem;
  width: 100%;
}
.question {
  font-size: 14px;
  background: transparent;
  padding: 0;
  color: #000;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
.message {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}
.label {
  cursor: pointer;
}
.mark {
  border: 1px solid var(--secondary-blue);
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 1rem;
  margin-left: 1rem;
  display: block;

  &:disabled {
    cursor: not-allowed;
  }
}

.error .question {
  color: #e74444;
}
.error .input {
  border-color: #e74444;
}
.req {
  color: #e74444;
  font-weight: 600;
}

.fieldset {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.tag {
  font-weight: 500;
  white-space: nowrap;
}
.tag:first-of-type {
  text-align: right;
}
.input {
  display: none;
}
.input:checked ~ .mark {
  background-color: var(--secondary-blue);
  transform: rotate(0deg) scale(1);
  opacity: 1;
}
.scaleWrap {
  display: flex;
  align-items: center;

  display: grid;
  grid-template-columns: 150px 1fr 150px;
  gap: 1rem;
  max-width: 900px;
}

@media screen and (max-width: 900px) {
  .mark {
    width: 32px;
    height: 32px;
    margin: 0 5px;
  }
  .scaleWrap {
    grid-template-columns: 100px 1fr 100px;
  }
  .tag {
    white-space: unset;
    padding-right: 8px;
    word-break: break-word;

    &:last-of-type {
      text-align: right;
      padding-left: 8px;
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .mark {
    margin: 0;
  }
  .tag {
    font-weight: 400;
  }
  .tag:first-of-type {
    text-align: left;
  }
  .tag:last-of-type {
    grid-column-end: 3;
    grid-row-start: 1;
  }
  .inputWrapper {
    margin-bottom: 2.5rem;
  }
  .question {
    margin-bottom: 1rem;
  }
  .scaleWrap {
    grid-template-columns: 1fr 1fr;
  }
  .fieldset {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
