.modal {
  padding: 1.2rem;
}
.ttl {
  margin-bottom: 12px;
  font-size: 22px;
  font-weight: 600;
  color: #101323;
}
.txt {
  font-size: 15px;
  margin-bottom: 1.5rem;
  color: #667085;
}
.btnSec {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #80808030;
  padding-top: 1.2rem;

  button {
    margin-left: 10px;
    padding: 8px 18px;
    font-weight: 400;

    &:first-of-type {
      border-color: #80808060;
    }

    &.red {
      background: #ff4444;
      border-color: #ff4444;
    }
  }
}
