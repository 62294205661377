.container {
  background: #fff;
  padding: 0 2rem 1rem;
  border-radius: 16px;
}
.tabs {
  display: flex;
  margin-left: -2rem;
  border-top-left-radius: inherit;
  border-radius: inherit;
  margin-bottom: 1rem;

  span {
    padding: 10px 2rem;
    font-size: 18px;
    border-bottom: 1px solid var(--primary-blue);

    &.active {
      background: var(--secondary-blue);
      border-color: var(--secondary-blue);
      color: #fff;
      font-weight: 600;
    }

    &:first-of-type {
      border-top-left-radius: inherit;
    }

    &:last-of-type {
      // border-top-right-radius: inherit;
      border-bottom-right-radius: 12px;
    }
  }
}

.tableRow,
.tableHeader {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1fr 50px;
  gap: 1rem;
  text-align: center;
  font-weight: 500;

  span:first-of-type {
    text-align: left;
  }
}

.draftTableRow,
.draftTableHeader {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr 120px;
  gap: 1rem;
  text-align: center;
  font-weight: 500;

  span:first-of-type {
    text-align: left;
  }
}
.createBtn {
  font-size: 13px;
  border-radius: 9px;
  padding: 12px 1.2rem;
  margin-left: auto;
  margin-bottom: 1.5rem;

  svg {
    margin-right: 10px;
    width: 15px;
  }
}

.dateFilter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  label {
    margin-left: 12px;
    width: 130px;

    span {
      font-size: 12px;
      color: #808080;
      margin-bottom: 4px;
      display: block;
    }

    input {
      display: block;
      width: 100%;
      border: 1px solid var(--primary-blue);
      border-radius: 4px;
      padding: 6px;
      background: transparent;
      padding-right: 6px;
    }
  }

  button {
    padding: 8px 12px;
    margin-top: auto;
    font-size: 12px;
    border-radius: 4px;
    margin-left: 12px;

    &.reset {
      border-color: red;
      color: red;
    }
  }
}
@media screen and (max-width: 900px) {
  .tableHeader,
  .draftTableHeader {
    display: none;
  }
  .tableRow,
  .draftTableRow {
    grid-template-columns: 1fr;
    text-align: left;
    gap: 1rem 12px;
    padding: 1rem;
  }

  .container {
    border-radius: 8px;
    margin-left: -1rem;
    width: calc(100% + 2rem);
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .tabs {
    // width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: calc(100% + 2rem);
    max-width: 500px;
    margin-left: -1rem;
    margin-bottom: 2rem;
    overflow: auto;

    span {
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center;
      min-width: 80px;

      &:last-of-type {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    }
  }
}
