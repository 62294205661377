.container {
  padding: 0 1rem;
  text-align: center;
  position: relative;

  &__txtSec {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__txt1 {
  }

  &__txt2 {
    color: #101828;
    font-weight: 700;
    font-size: 32px;
    margin-right: 8px;
  }
}
.chart {
  width: 200px;
  height: 200px;
  margin: auto;
  margin-top: -30px;
  margin-bottom: -40px;
}
