.navWrap {
  padding-top: 2rem;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1;
  left: 0;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d0d5dd;
  border-radius: 100px;
  padding: 10px 1.4rem;
  background: rgb(249 250 251 / 80%);
}
.whiteBg {
  background: #fff;
}
.logo {
  svg {
    width: 140px;
    height: auto;
    margin-bottom: -8px;
  }
}

.navItems {
  display: flex;
  gap: 3rem;
  font-size: 15px;

  a.active {
    color: var(--secondary-blue);
    font-weight: 500;
  }
}

.authLinks {
  display: flex;
  gap: 12px;

  a {
    padding: 14px 1.3rem;
    border-radius: 100px;
    font-size: 15px;
    color: var(--secondary-blue);
    background: transparent;
    font-weight: 500;

    &:last-of-type {
      background: var(--secondary-blue);
      color: #fff;
    }
  }
}
.hamburger {
  display: none;
}

@media screen and (max-width: 800px) {
  .navWrap {
    padding-top: 0;
  }
  .container {
    height: 100%;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .hamburger * {
    transition: 0.3s;
  }
  .hamburger {
    display: flex;
    flex-direction: column;
    width: 24px;
    height: 24px;
    padding: 6px 0;
    justify-content: space-between;
    background: transparent;
    border: none;
    cursor: pointer;

    div {
      background: #101323;
      height: 2px;
      width: 100%;
      border-radius: 4px;
    }
  }

  .closeMenuBtn {
    div:first-of-type {
      transform: rotate(45deg) translate(4px, 4px);
    }
    div:nth-of-type(2) {
      transform: rotate(-45deg) translate(4px, -3px);
    }

    div:last-of-type {
      display: none;
    }
  }
  .openMenu {
    height: 100vh;
    background: #fff;
    z-index: 1;
  }
  .navItems {
    flex-direction: column;
    gap: 1rem;
    font-family: "Darker Grotesque";
    font-size: 32px;
    font-weight: 500;
  }
  .authLinks {
    flex-direction: column;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  .mobileNav {
    margin-top: 4rem;
  }
}
