.wrapper {
  padding: 2rem 1rem 1rem 1rem;
}
.container {
  padding-top: 1rem;
}

.openEndedSec {
  &__ttl {
    font-weight: 500;
    margin-bottom: 1rem;
    color: var(--secondary-blue);
  }
}
.info {
  color: var(--secondary-blue);
  border: 1px dashed currentColor;
  border-radius: 8px;
  padding: 10px 12px;
  font-size: 14px;
  display: grid;
  gap: 12px;
  grid-template-columns: 16px 1fr;
  margin-bottom: 1rem;
  width: fit-content;
  align-items: center;

  svg {
    width: 20px;
  }
}
.openEnded {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;

  > div {
    border-bottom: 2px solid #fafafb;
    padding-bottom: 1rem;
  }

  &__info {
    border-radius: 10px;
    background: #fafafb;
    padding: 1rem;
    color: #667085;
    margin-bottom: 1rem;

    &__hd {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        transform: rotate(-90deg);
        &.rotate {
          transform: rotate(90deg);
        }
      }
    }
    > p {
      white-space: break-spaces;
    }
    &__ttl {
      color: #101323;
    }

    &__txt {
      color: #667085;
      margin-top: 12px;
    }
    button {
      display: none;
    }
  }

  &__rating {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;

    > p {
      margin-right: 10px;
    }
  }
}

.scored {
  margin-bottom: 2rem;
  background: #fafafb;
  border-radius: 10px;
  padding: 1rem;

  &__question {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-top: 2px solid #eaecf0;
    padding-top: 2rem;

    &__ttl {
      font-weight: 600;
      margin-bottom: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #101323;

      span {
        font-weight: 300;
        color: #4e4e4e;
        display: flex;
        align-items: center;

        span {
          padding: 2px 10px;
          border-radius: 4px;
          border: 1px solid rgba(78, 78, 78, 0.67);
          font-size: 13px;
          margin-right: 6px;
        }
      }
    }
  }

  &__instruction {
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 1rem;
  }

  &__score {
    display: grid;
    grid-template-columns: 120px 1fr 100px;
    gap: 1rem;
    align-items: center;

    &__chart {
      width: 120px;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        font-weight: 700;
      }

      &__score {
        &.green {
          color: #149b32;
        }

        &.orange {
          color: #f29339;
        }

        &.red {
          color: #d11a2a;
        }

        &.blue {
          color: #6361f9;
        }
      }
    }

    &__info {
      p:first-of-type {
        color: #101323;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 16px;
      }
      p:last-of-type {
        color: #667085;
      }
    }

    button {
      color: var(--primary-blue);
      text-decoration: underline;
      background: transparent;
      border: none;
      font-size: 14px;
      font-weight: 500;

      svg {
        display: none;
      }
    }
  }

  .options {
    margin-left: 1rem;
    p {
      margin-bottom: 8px;
      display: flex;
      position: relative;
      padding: 4px;
      align-items: center;
      border-radius: 4px;

      svg {
        width: 24px;
        margin-left: auto;

        &.wrongAnswer {
          path {
            stroke-width: 2;
            fill: #e74444;
            fill-opacity: 1;
          }
        }
        &.rightAnswer {
          path {
            stroke: #605bff;
            stroke-width: 2;
          }
        }
      }

      &.correct {
        border: 1px solid rgba(20, 155, 50, 0.58);
        background: rgba(20, 155, 50, 0.11);
      }

      &.wrong {
        border: 1px solid rgba(231, 68, 68, 0.58);
        background: rgba(231, 68, 68, 0.11);
      }
    }
  }

  div.radio {
    p {
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        border: 1px solid var(--secondary-blue);
        display: block;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
    .selected::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      display: block;
      left: 7px;
      top: 9px;
      background: var(--secondary-blue);
      border-radius: 50%;
    }
  }

  div.check {
    p {
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        border: 1px solid var(--secondary-blue);
        display: block;
        margin-right: 10px;
        border-radius: 2px;
      }
    }
    .selected {
      &::before {
        background-color: var(--secondary-blue);
      }

      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        display: block;
        background: url(../../../../assets/icons/tick.svg);
        background-image: url(../../../../assets/icons/tickWhite.svg);
        background-size: contain;
      }
    }
  }
}

.full {
  background: #fafafb;
  display: flex;
  align-items: center;
  padding: 2rem;
  margin-bottom: 3rem;

  > p {
    font-weight: 600;
    font-size: 16px;
    margin-right: 20%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 0;
  }
  .scored {
    margin-bottom: 1rem;
    &__score {
      grid-template-columns: 80px 1fr 24px;

      &__chart {
        width: 80px;

        span {
          font-size: 16px;
        }
      }

      button {
        span {
          display: none;
        }

        svg {
          display: block;
          transform: rotate(-90deg);
          &.rotate {
            transform: rotate(90deg);
          }
        }
      }
    }

    &__question {
      &__ttl {
        display: flex;
        flex-direction: column;
        align-items: unset;
        gap: 10px;

        > span {
          margin-left: auto;
        }
      }
    }
  }
}
