.inputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  background: transparent;
  margin-bottom: 1.5rem;
  width: 100%;

  .input {
    width: 100%;
    padding: 10px;
    padding-left: 60px;
    border: 1px solid #dae0e6;
    box-shadow: 0px 1px 2px 0px #1018280a;
    border-radius: 6px;
    
    &:focus {
      border-color: rgba(1, 33, 105, 0.5);
      box-shadow: unset;
    }
  }
}
.label {
  font-size: 14px;
  background: transparent;
  padding: 0;
  color: #101323;
  font-weight: 500;
  margin-bottom: 8px;
}
.message {
  font-size: 12px;
  color: #e74444;
  display: block;
  margin-top: 8px;
}

.error label {
  color: #e74444;
}
.error .input {
  border-color: #e74444;
}
.req {
  color: #e74444;
  font-weight: 600;
}

.hasValue {
  background: #fbfbff;
}
