.container {
  max-width: 1250px;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  user-select: none;

  * {
    user-select: none;
  }
}
.overview {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 800px;
}
.header {
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05);
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;

  section {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;

    .logoSec {
      display: flex;
      align-items: center;
      gap: 1rem;
      overflow: hidden;
    }
  }

  h1 {
    font-size: 24px;
    margin: 6px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: auto;
  }
  .logo {
    height: 45px;
    width: auto;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b6b75;
  padding-top: 3rem;
  padding-bottom: 2rem;
  font-size: 16px;

  svg {
    width: 90px;
    margin-left: 6px;
  }
}
.body {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main {
  background: #feffff;
  padding-top: 70px;
  min-height: 100vh;

  &__pad {
    padding-top: 140px;
  }
}
.timer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    background: rgba(20, 155, 50, 0.22);
    padding: 4px;
    font-weight: 600;
    width: 54px;
    height: 48px;
    // margin-left: 1rem;
    justify-content: flex-end;

    span:last-of-type {
      font-size: 12px;
    }

    &:nth-of-type(2) {
      background: rgba(96, 91, 255, 0.22);
    }
    &:nth-of-type(3) {
      background: rgba(231, 68, 68, 0.22);
    }
  }
}
.info {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  top: 68px;
  width: 100%;
  background: #fff;
  z-index: 1;
}
.progressWrap {
  // margin-top: 2rem;
  display: flex;
  white-space: nowrap;
  align-items: center;
  margin-bottom: 8px;
}
.progress {
  position: relative;
  z-index: 2;
  color: var(--bg-main);
  display: flex;
  position: relative;
  width: 100%;
  margin-right: 1rem;

  &__bar {
    width: 0px;
    height: 10px;
    background: var(--secondary-blue);
    transition: width 0.3s ease-out;
    position: absolute;
    z-index: 1;
    border-radius: 10px;

    &__white {
      background: #d4d4df;
      width: 100%;
      height: 10px;
      border-radius: 10px;
    }
  }
}
.count {
}

.scheduled {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: #03022920;
  display: flex;
  justify-content: center;
  align-items: center;

  section {
    background: #fff;
    padding: 2rem 1.5rem;
    border-radius: 12px;
    text-align: center;
    width: 90%;
    max-width: 320px;
  }

  .close {
    margin-left: auto;
    display: block;
    background: #e7444420;
    border-radius: 50%;
    padding: 4px;
    width: 24px;
    height: 24px;

    path {
      fill: #e74444;
    }
  }

  .txt1 {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .days {
    margin-bottom: 6px;
    span {
      border-radius: 10px;
      background: rgba(20, 155, 50, 0.22);
      font-size: 80px;
      padding: 0 20px;
      font-weight: 600;
      margin: 0 6px;
    }
  }

  .txt2 {
    font-weight: 600;
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .header {
    h1 {
      font-size: 20px;
    }
    .logo {
      height: 32px;
    }
  }
  .timer {
    gap: 8px;
  }
}
