.container {
  background: #fff;
  border-radius: 8px;
  padding: 4rem 1rem;
}
.body {
  max-width: 500px;
  margin: auto;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  h1 {
    font-weight: 600;
  }

  &__tabs {
    background: #eaecf0;
    padding: 2px;
    border-radius: 6px;

    button {
      padding: 6px 1rem;
      border-radius: 4px;
      border: none;
      color: #344054;
      font-weight: 500;
      font-size: 15px;

      &.active {
        background: #fff;
        color: #101828;
      }
    }

    &--1 {
      button {
        width: 120px;
      }
    }

    &--2 {
      button {
        width: 80px;
      }
    }
  }
}
.cycle {
  margin-bottom: 2rem;

  &__ttl {
    color: #344054;
    margin-bottom: 10px;
    font-weight: 500;
  }

  fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #101828;

      input {
        accent-color: #101828;
      }

      span {
        color: var(--secondary-blue);
        font-size: 12px;
      }
    }
  }
}
.info {
  &__txt {
    color: #475467;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    font-size: 15px;
  }

  &__fee {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.3rem;

    p {
      color: #101828;
      font-weight: 700;
      font-size: 44px;
      margin-right: 8px;
      line-height: 0.8;
    }

    span {
      color: #475467;
      font-size: 16px;
      font-weight: 500;
    }
  }

  ul {
    list-style: none;
    color: #475467;
    margin-bottom: 2rem;

    li {
      display: flex;
      align-items: center;
      line-height: 1.5;
      margin-bottom: 12px;
      gap: 10px;

      // svg {
      //   margin-right: 10px;
      // }
    }
  }
}
.breakdown {
  &__roles {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: flex-start;
    font-size: 16px;
    border-top: 1px solid var(--gray-200, #eaecf0);
    border-bottom: 1px solid var(--gray-200, #eaecf0);
    padding: 1rem 0;

    &__ttl {
      color: #000000;
      font-weight: 500;
    }

    &__cost {
      color: #000000;
      font-weight: 500;
      display: flex;
      gap: 6px;

      &__old {
        color: #475467;
        text-decoration: line-through;
      }

      &__bonus {
        color: var(--secondary-blue);
        font-size: 12px;
        text-align: right;
      }
    }
  }
  &__total {
    color: #475467;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    &__ttl {
      font-size: 16px;
    }

    &__cost {
      font-size: 24px;
    }
  }
}
.btnSec {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    margin-left: 12px;
    border-radius: 6px;
    padding: 12px 16px;
    border: none;
  }

  &__close {
    border-radius: 10px;
    background: var(--gray-200, #eaecf0);
    color: #475467;
  }

  &__upgrade {
    border-radius: 10px;
    background: var(--secondary-blue);
    box-shadow: 0px 0px 2px 0px #a29eff;
    color: #fff;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
