.pagination {
  border-top: var(--border-1);
}

.status {
  text-transform: capitalize;
  border-radius: 24px;
  width: fit-content;
  font-weight: 500;
  padding: 6px 10px;
  text-align: center;
  min-width: 70px;
  display: block;
  margin: auto;

  &.active {
    color: #149b32;
    background: #3a974c10;
  }

  &.scheduled {
    color: #F29339;
    background: #F2933910;
  }

  &.closed {
    color: #D11A2A;
    background: #D11A2A10;
  }
}
.copyBtn {
  color: #012169;
  border-radius: 10px;
  background: rgba(39, 133, 229, 0.10);
  border: none;
  outline: none;
  padding: 10px 1.2rem;
  white-space: nowrap;
  font-weight: 500;
  margin-left: auto;
  display: block;

  svg {
    display: none;
  }
}
.tableBodyRow {
  .label {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .tableBodyRow {
    .label {
      display: block;
      font-weight: 400;
      color: #00000060;
      margin-bottom: 4px;
      font-size: 14px;
    }

    span {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .copyBtn {
    background: transparent;
    text-decoration: underline;
    display: flex;
    align-items: center;
    padding: 0;

    svg {
      display: block;
      margin-left: 4px;
    }
  }
}
