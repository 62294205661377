.container {
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
    width: 24px;
  }
}
.fill {
  path {
    stroke: #ffcb12;
    fill: #ffcb12;
  }
}
