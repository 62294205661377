.ttl {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 20px;
  margin-top: 1rem;
}
.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  gap: 1rem;
  margin-bottom: 2rem;

  p {
    span:first-of-type {
      margin-right: 6px;
    }

    &:nth-of-type(even) {
      margin-left: auto;
    }
  }
}

.instructions {
  margin-top: 2rem;
  font-weight: 300;

  ol {
    padding-left: 1rem;
    margin: 6px 0 1.5rem;
    line-height: 1.5;
  }
}
.areas {
  text-align: center;
  margin: 2rem 0;

  font-weight: 300;
}
.sections {
  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  justify-content: center;
  margin: 2rem 0 3rem;

  span {
    border-radius: 20px;
    border: 1px solid #000;
    background: rgba(80, 200, 120, 0.2);
    padding: 6px 12px;
    margin: 0 10px 1rem;
  }
}
.btnSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 3rem;

  button {
    width: 160px;
    border-radius: 8px;
    font-size: 14px;

    &:first-of-type {
      color: var(--secondary-blue);
    }
  }
}

.logo {
  height: 40px;
  margin-top: -40px;
}

.title {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  input {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;

    &::placeholder {
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
    }
  }
}

.instruction {
  textarea {
    line-height: 1.5;
    padding-bottom: 20px;
  }
}
.wordLimit {
  position: absolute;
  font-size: 12px;
  background: #fbfbff;
  top: 179px;
  padding-bottom: 5px;
  padding-right: 5px;
  right: 7px;
}
